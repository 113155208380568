import countryCodes from 'country-codes-list'

const countriesList = countryCodes.customList('countryCode', '{countryNameEn}')

export const countryOptions = Object.entries(countriesList).map(([key, value]) => {
  return { value: key, label: value as string }
})

const logoPositions = [
  'top-left',
  'top',
  'top-right',
  'right',
  'bottom-right',
  'bottom',
  'bottom-left',
  'left',
  'center',
]

export const logoOptions = logoPositions.map((option) => {
  return { value: option, label: option }
})

export const filesOrderOptions = ['default', 'ordered', 'date'].map((value) => ({ value, label: value.replace(/^\w/, (c) => c.toUpperCase()) }))