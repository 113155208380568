import { Dispatch, FC, SetStateAction } from 'react'
import { useMerchantState } from 'src/contexts'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { backgroundColor, borderColor, primaryColor } from 'src/styles'

interface FontButtonProps {
  fontId: number
  fontFamily: string
  currentFontId: number
  setCardError: Dispatch<SetStateAction<string | undefined>>
}

const FontButton: FC<FontButtonProps> = ({ currentFontId, fontId, fontFamily, setCardError }) => {
  const { editTemplate } = useMerchantState()

  const updateFont = async () => {
    setCardError(undefined)
    if (editTemplate) {
      const info = {
        font_id: fontId,
      }
      try {
        await editTemplate(info)
      } catch (e) {
        if (isErrorResource(e)) {
          setCardError(e.data.error.message)
        }
      }
    }
  }
  const isSelected = currentFontId === fontId
  const isFirst = fontId === 1
  const isLast = fontId === 8
  const borderTop = isFirst ? 15 : 0
  const borderBottom = isLast ? 15 : 0

  const style = {
    padding: '8px 6px',
    borderBottom: isLast ? 'none' : `1px solid ${borderColor}`,
    backgroundColor: isSelected ? primaryColor : 'transparent',
    color: isSelected ? backgroundColor : primaryColor,
    borderTopLeftRadius: borderTop,
    borderTopRightRadius: borderTop,
    borderBottomLeftRadius: borderBottom,
    borderBottomRightRadius: borderBottom,
    cursor: 'pointer',
  }

  return (
    <div style={style} onClick={updateFont}>
      <div style={{ fontFamily }}>{fontFamily}</div>
    </div>
  )
}

export default FontButton
