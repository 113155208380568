import { FC } from 'react'
import { IconsProps } from './Icon'

const DeleteIcon: FC<IconsProps> = ({ color }) => (
  <svg width="20" height="24" viewBox="0 0 24 24">
    <path fill='none' d="M0 0h24v24H0V0z" />
    <path fill={color} d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
  </svg>
)

export default DeleteIcon
