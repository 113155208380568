export const backgroundColor = 'rgb(255, 255, 255)'
export const borderColor = 'rgba(229, 229, 229, 0.5)'
export const greenColor = 'rgb(46, 170, 46)'
export const lightGreyColor = 'rgba(17, 17, 17, 0.5)'
export const lightTextColor = 'rgb(255, 255, 255)'
export const linkColor = 'rgb(0, 186, 255)'
export const orderCancelledColor = 'rgb(191, 36, 81)'
export const orderPendingColor = 'rgb(246, 134, 87)'
export const orderConfirmedColor = 'rgb(246, 179, 82)'
export const orderCompletedColor = 'rgb(46, 170, 46)'
export const primaryColor = 'rgb(17, 17, 17)'
export const primaryColorLighter = 'rgba(0, 0, 0, 0.1)'
export const subtitleColor = 'rgb(187,187,187)'
export const templateColor = 'rgb(210, 210, 210)'
export const warningColor = 'rgb(244, 63, 63)'
