import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import { FontResource } from 'src/resources'
import { FontsService } from 'src/services'

interface FontsContextProps {
  isLoading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  fonts: FontResource[] | undefined
  setFonts: Dispatch<SetStateAction<undefined | FontResource[]>>
  getFonts: () => Promise<FontResource[] | undefined>
}

const FontsContext = createContext<Partial<FontsContextProps>>({})

const FontsProvider: FC<ReactNode> = ({ children }) => {
  const [fonts, setFonts] = useState<undefined | FontResource[]>()
  const [isLoading, setLoading] = useState(false)

  const getFonts = async () => {
    setLoading(true)
    try {
      const { data } = await FontsService.getFonts()
      setFonts(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const context = {
    isLoading,
    fonts,
    getFonts,
    setFonts,
  }

  return <FontsContext.Provider value={context}>{children}</FontsContext.Provider>
}

const useFontsState = (): Partial<FontsContextProps> => {
  const context = useContext(FontsContext)
  if (context === undefined) {
    throw new Error('useFontState must be used within a FontsContext')
  }
  return context
}

export { FontsProvider, useFontsState }
