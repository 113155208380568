import { FC } from 'react'
import { Icon } from '../../Icon'
import './InnerWrapper.scss'

interface UnauthWrapperProps {
  title: string
  entry: string
  hasError?: boolean
  error?: string
}

const UnauthWrapper: FC<UnauthWrapperProps> = ({ children, title, entry, error, hasError }) => {
  return (
    <div className="innerWrapper" >
      <div className="header">
        <Icon type="logo" />
        <div className="title">{title}</div>
        <div className="entry">{entry}</div>
        {hasError && <div className="error">{error}</div>}
        {children}
      </div>
    </div>
  )
}

export default UnauthWrapper
