import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Input } from "src/components";
import { useMerchantState } from "src/contexts";
import { sleep } from "src/helpers/sleep";
import { UserResource } from "src/resources";
import { isErrorResource } from "src/resources/errors/ErrorResource";
import { LoginValues } from "src/types";
import * as Yup from "yup";
import "./LoginForm.scss";

interface FormProps {
  login: (values) => Promise<void | UserResource>;
}

const LoginForm: FC<FormProps> = ({ login }) => {
  const { getMerchant } = useMerchantState();
  const history = useHistory();
  const initialValues = { email: "", password: "" };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required."),
    password: Yup.string()
      // I guess only checking this on creation of password is enough.
      // .min(8, "Password must be 8 characters or longer")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   "Password must contain an uppercase, a lowercase, a number and one special character"
      // )
      .required("This field is required."),
  });

  const emailText = "Email address";
  const passwordText = "Password";

  const onSubmit = async (values: LoginValues) => {
    // TODO: Since login is an async call hasError is always false since the api request wasn't done yet.
    // login(values);
    // if (!hasError) {
    //   history.push("/");
    // }
    setErrorMessage(undefined);
    setIsSubmitting(true);
    try {
      // This goes way too fast
      await sleep(300);
      await login(values);
      if (getMerchant) await getMerchant();
      history.push("/");
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleBlur, values, isValid, handleSubmit }) => {
        return (
          <Form
            className="loginForm"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Input
              label={emailText}
              type="email"
              name="email"
              id="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={emailText}
            />
            <Input
              label={passwordText}
              type="password"
              name="password"
              id="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={passwordText}
            />
            {errorMessage ? <p className="errorText">{errorMessage}</p> : null}
            <Button
              type="submit"
              disabled={!isValid}
              text="Login"
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
