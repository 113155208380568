import { FC } from 'react'
import { backgroundColor } from 'src/styles'
import { IconsProps } from './Icon'

const SpineArrow: FC<IconsProps> = ({ deg }) => {
  const transform = `rotate(${deg})`
  return (
    <svg viewBox="0 0 22 7" fill="none" transform={transform}>
      <path d="M21 0.5L11 5.5L1 0.499998" stroke={backgroundColor} />
    </svg>
  )
}

export default SpineArrow
