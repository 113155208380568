import { FC } from 'react'
import './FrontCoverLayout.scss'

interface FrontCoverLayoutProps {
  coverLayoutId: number
  coverStyle: { backgroundColor: string; color: string }
  hasTitle: boolean
  fontFamily: string
}

const FrontCoverLayout: FC<FrontCoverLayoutProps> = ({
  coverLayoutId,
  coverStyle,
  hasTitle,
  fontFamily,
}) => {
  const showLandscapeCover = coverLayoutId === 1 || coverLayoutId === 46
  const isColumns = coverLayoutId === 2
  const isColumn = coverLayoutId === 47
  const isColumnAndTitle = coverLayoutId === 47
  const isColumnsAndTitle = coverLayoutId === 3
  const isTitle = coverLayoutId === 4 || coverLayoutId === 46
  const isMTT = coverLayoutId === 27
  const frontCoverText = hasTitle ? 'Title' : 'Front cover'
  const hasColumns = isColumnsAndTitle || isColumns
  return (
    <div className="cover" style={coverStyle}>
      {isMTT && <div className="MTTCover"></div>}
      {showLandscapeCover && <div className="landscapeCover"></div>}
      {isColumn && (
        <div className="columnsCoverWrapper columnCoverWrapper">
          <div className="columnsCoverInner">
            <div className="columnsCover"></div>
          </div>
          {isColumnAndTitle && (
            <div className="titleWithColumns" style={{ fontFamily }}>
              {frontCoverText}
            </div>
          )}
        </div>
      )}
      {hasColumns && (
        <div className="columnsCoverWrapper">
          <div className="columnsCoverInner">
            <div className="columnsCover"></div>
            <div className="columnsCover"></div>
          </div>
          {isColumnsAndTitle && (
            <div className="titleWithColumns" style={{ fontFamily }}>
              {frontCoverText}
            </div>
          )}
        </div>
      )}
      {isTitle && <div style={{ fontFamily }}>{frontCoverText}</div>}
    </div>
  )
}

export default FrontCoverLayout

