import { CSSProperties, Dispatch, FC, SetStateAction, useState } from 'react'
import { SketchPicker } from 'react-color'
import { Button } from 'src/components'
import { sleep } from 'src/helpers/sleep'
import { TemplateResource } from 'src/resources'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { EditTemplateValues } from 'src/types'
import './ColorPicker.scss'

interface ColorPickerProps {
  label: string
  value: string
  name: string
  editTemplate: ((info: EditTemplateValues) => Promise<TemplateResource | undefined>) | undefined
  setColor: Dispatch<SetStateAction<string | undefined>>
  setError: Dispatch<SetStateAction<string | undefined>>
}

const ColorPickerInput: FC<ColorPickerProps> = ({
  label,
  value,
  name,
  setColor,
  editTemplate,
  setError,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  // to avoid sending a PATCH request when no new color is selected
  const [selectedCount, setSelectedCount] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const togglePicker = async () => {
    setError(undefined)
    if (selectedCount && displayColorPicker && editTemplate) {
      const info = {
        [name]: value,
      }
      setIsSubmitting(true)
      try {
        await sleep(300)
        await editTemplate(info)
      } catch (e) {
        if (isErrorResource(e)) {
          setError(e.data.error.message)
        }
      } finally {
        setIsSubmitting(false)
      }
    }
    setDisplayColorPicker(!displayColorPicker)
    setSelectedCount(0)
  }

  const onChangeComplete = (val) => {
    setColor(val.hex)
    setSelectedCount(1)
  }

  const isColorSelected = selectedCount === 1
  const buttonText = isColorSelected ? 'save' : 'pick a color'
  const buttonTheme = isColorSelected ? 'dark' : 'transparent'

  const popover = {
    position: 'absolute',
    left: 70,
    zIndex: 10,
    bottom: 70,
  } as CSSProperties

  const cover = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  } as CSSProperties

  return (
    <div style={{ position: 'relative' }}>
      {displayColorPicker && (
        <div style={popover}>
          <div style={cover} onClick={togglePicker} />
          <SketchPicker color={value} onChangeComplete={onChangeComplete} disableAlpha />
        </div>
      )}
      <div className="ColorInputWrapper">
        <span style={{ backgroundColor: value }} className="swatch" />
        {label}
        <input
          type="text"
          name={name}
          placeholder={label}
          value={value}
          id={name}
          className="colorInput"
          onChange={onChangeComplete}
        />
        <Button
          bold
          theme={buttonTheme}
          onClick={togglePicker}
          text={buttonText}
          mb={1}
          mt={1}
          height={25}
          isLoading={isSubmitting}
          width={20}
        />
      </div>
    </div>
  )
}

export default ColorPickerInput
