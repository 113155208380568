import { FC, useState } from 'react'
import { Wrapper } from 'src/components'
import { useAuthState, useMerchantState } from 'src/contexts'
import './Account.scss'
import { AccountForm, MerchantCard, TeamCard, UserCard } from './components'
import { reorderTeam } from './helpers'

const Account: FC = () => {
  const { user } = useAuthState()
  const { merchant, generateApiKey, apiKey, setApiKey } = useMerchantState()
  const [showUserModal, setShowUserModal] = useState(false)
  const [showMerchantModal, setShowMerchantModal] = useState(false)
  const [showTeamModal, setShowTeamModal] = useState(false)

  const toggleUserModal = () => setShowUserModal(!showUserModal)
  const toggleMerchantModal = () => setShowMerchantModal(!showMerchantModal)
  const toggleTeamModal = () => setShowTeamModal(!showTeamModal)
  const hideApiKey = () => setApiKey && setApiKey('')

  const team = user && merchant && merchant.users && reorderTeam(merchant.users, user.id)

  return (
    <Wrapper>
      {merchant && user && (
        <div className="AccountWrapper">
          <UserCard user={user} toggleModal={toggleUserModal} />
          <MerchantCard
            merchant={merchant}
            toggleModal={toggleMerchantModal}
            role={user.role}
            generateApiKey={generateApiKey}
            isAdmin={user.isAdmin}
            apiKey={apiKey}
            hideApiKey={hideApiKey}
          />
          <TeamCard team={team} toggleModal={toggleTeamModal} />
          {showUserModal && <AccountForm toggleModal={toggleUserModal} user={user} />}
          {showMerchantModal && (
            <AccountForm toggleModal={toggleMerchantModal} merchant={merchant} />
          )}
          {showTeamModal && (
            <AccountForm merchantId={merchant.id} toggleModal={toggleTeamModal} team={team} />
          )}
        </div>
      )}
    </Wrapper>
  )
}

export default Account
