import { FC } from 'react'
import './SuccessAlert.scss'

interface SuccessAlertProps {
  message: string
  noDisappear?: boolean
  onClose: () => void
}

const SuccessAlert: FC<SuccessAlertProps> = ({ onClose, message, noDisappear, children }) => {
  if (!noDisappear) {
    // to remove automatically alert after 4 sec
    setTimeout(() => onClose(), 4000)
  }
  return (
    <div className="AlertContainer">
      <div className="alertClose" onClick={onClose}>
        Close
      </div>
      <div className="alertMessage">{message}</div>
      {children}
    </div>
  )
}

export default SuccessAlert
