import { FC } from 'react'
import './DropDownInput.scss'

interface Option {
  value: string
  label?: string
}

interface DropDownInputProps {
  label?: string
  name: string
  type: string
  value?: string
  className?: string
  id: string
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: Option[]
  placeholder?: string
}

export const DropDownInput: FC<DropDownInputProps> = ({
  name,
  value,
  id,
  onBlur,
  onChange,
  options,
  className,
  placeholder
}) => {
  const defaultOption: Option[] = [{ value: 'none', label: placeholder ? placeholder : `Select a ${name.toLowerCase()}` }]
  const newOptions = defaultOption.concat(options)
  return (
    <select
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      className={className}>
      {newOptions.map(({ value, label }) => {
        const defaultOption = value === 'none'
        return <option value={value} label={label} key={value} hidden={defaultOption} />
      })}
    </select>
  )
}

export default DropDownInput
