export interface ErrorResource {
  data: {
    error: {
      message: string
      statusCode: number
      property: string
    }
  }
}

export const isErrorResource = (e: unknown): e is ErrorResource => {
  return typeof e === 'object' && e !== null && 'data' in e
}