export interface ILayoutResource {
    id: number
    is_cover: boolean
    is_chapter_cover: boolean
    is_protected: boolean
    is_enabled: boolean
    title: string
    gap: number
    grid_template_areas: string
    max_portrait: number
    max_landscape: number
    thumbnail_url: string
    created_at: Date
    updated_at: Date
}

export class LayoutResource {
    public readonly id: number

    public title: string

    public gap: number

    public gridTemplateAreas?: string

    public maxPortrait?: number

    public maxLandscape?: number

    public createdAt?: Date

    public updatedAt?: Date

    public is_cover?: boolean

    public is_chapter_cover?: boolean
    
    public is_protected?: boolean

    public is_enabled?: boolean

    public thumbnail_url?: string

    constructor(layout: ILayoutResource) {
        this.id = layout.id
        this.title = layout.title
        this.is_cover = layout.is_cover
        this.is_chapter_cover = layout.is_chapter_cover
        this.is_protected = layout.is_protected
        this.is_enabled = layout.is_enabled
        this.gap = layout.gap
        this.gridTemplateAreas = layout.grid_template_areas
        this.maxPortrait = layout.max_portrait
        this.maxLandscape = layout.max_landscape
        this.thumbnail_url = layout.thumbnail_url
        this.createdAt = layout.created_at ? new Date(layout.created_at) : new Date()
        this.updatedAt = layout.updated_at ? new Date(layout.updated_at) : new Date()
    }
}
