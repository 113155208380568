import { FC } from 'react'
import { templateColor } from 'src/styles'

interface TextLinePlaceholderProps {
  isSmall?: boolean
  color?: string
}

const TextLinePlaceholder: FC<TextLinePlaceholderProps> = ({ isSmall, color }) => {
  const lineStyle = {
    backgroundColor: color || templateColor,
    borderRadius: 3,
    height: 4,
    width: isSmall ? 49 : 71,
  }

  return <div style={lineStyle}></div>
}

export default TextLinePlaceholder
