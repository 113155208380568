import { FC, useMemo, useRef } from 'react'
import { Wrapper } from 'src/components'
import { useTemplateState } from 'src/contexts'
import { TemplatesCard } from './components'
import './Templates.scss'

const Templates: FC = () => {
  const { templateLayouts, getTemplateLayouts } = useTemplateState()
  const { current: currentLayouts } = useRef(getTemplateLayouts)

  useMemo(() => {
    if (!currentLayouts) return
    ;(async () => {
      try {
        await currentLayouts()
      } catch (e) {
        console.error(e)
      }
    })()
  }, [currentLayouts])

  const pageLayouts = templateLayouts?.filter(
    (layout) => layout.is_cover === false && layout.is_chapter_cover === false,
  )

  const isMTT = templateLayouts?.filter((pageLayout) => pageLayout.id === 27) !== []
  return (
    <Wrapper>
      <div className="templatesWrapper">
        <div className="templatesTitle">Current template</div>
        <div className="templatesInner">
          <div>
            <div className="specsBox">
              Min amount of pages <span className="bookSpecs">24 pages</span>
            </div>
            <div className="specsBox">
              Book type <span className="bookSpecs">Softcover</span>
            </div>
          </div>
          {pageLayouts && <TemplatesCard options={pageLayouts} isMTT={isMTT} />}
        </div>
      </div>
    </Wrapper>
  )
}

export default Templates
