import { IMerchantResource } from '../merchant/MerchantResource';


export interface IUserResource {
    id: number;
    first_name: string;
    last_name: string;
    is_verified: boolean
    access_token: string;
    created_at?: Date
    email: string
    merchant: IMerchantResource
    role: 'user' | 'admin'
    updated_at?: Date
}

export interface UserResponse {
    data: IUserResource
}

export class UserResource {

    public readonly id: number;

    public firstName: string;

    public lastName: string;

    public accessToken?: string;

    public email: string;

    public role: 'user' | 'admin';

    public organisation: string;

    public isVerified?: boolean

    public createdAt: Date

    public updatedAt?: Date

    constructor(user: IUserResource) {
        this.id = user.id;
        this.firstName = user.first_name;
        this.lastName = user.last_name;
        this.accessToken = user.access_token;
        this.role = user.role;
        this.email = user.email;
        this.isVerified = user.is_verified || user.merchant?.is_verified
        this.organisation = user.merchant?.name
        this.createdAt = user.created_at ? new Date(user.created_at) : new Date();
        this.updatedAt = user.updated_at ? new Date(user.updated_at) : new Date();
    }

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public get initials(): string {
        return `${this.firstName[0]}${this.lastName[0].toUpperCase()}`;
    }

    public get isAdmin(): boolean {
        return this.role === 'admin';
    }
}
