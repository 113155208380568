import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const API = async <T = undefined>(options: AxiosRequestConfig): Promise<T> => {
  const token = await Cookies.get('@token');
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL ?? "",
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  const onSuccess = (response: AxiosResponse<T>) => response.data;

  const onError = (error: AxiosError) => Promise.reject(error.response || error.message);

  return client(options).then(onSuccess).catch(onError);
};

export default API;
