import { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuthState } from 'src/contexts'
import { Icon } from '../Icon'
import { InitialsBadge } from '../InitialsBadge'
import './NavBar.scss'

interface NavBarProps {
  userName: string
}

const NavBar: FC<NavBarProps> = ({ userName }) => {
  const [showModal, setShowModal] = useState(false)
  const { logout } = useAuthState()
  const links = ['Orders', 'Branding', 'Templates', 'Preview', 'Account']
  const initials = userName
    .split(' ')
    .map((i) => i[0])
    .join('')

  const toggleModal = () => setShowModal(!showModal)
  const iconDirection = showModal ? 'up' : 'down'
  return (
    <div className="navBar">
      <div className="navTop">
        <div className="navIcon">
          <NavLink to="/">
            <Icon type="logo" />
          </NavLink>
        </div>
        <div className="navUserInfo ">
          <NavLink to="/account" className="navUserInfo">
            <InitialsBadge initials={initials} color="red" />
          </NavLink>
          <button onClick={toggleModal}>
            <div className="navUserName">{userName}</div>
            <Icon type="arrow" direction={iconDirection} />
          </button>

          {showModal && (
            <button className="logoutModal" onClick={logout}>
              Log out
            </button>
          )}
        </div>
      </div>
      <nav>
        {links.map((link) => {
          const linkTo = `/${link.toLowerCase()}`
          const pathName = window.location.pathname //returns the current url minus the domain name
          const isOrders = link === 'Orders' && pathName === ('/' || '/orders')
          const to = isOrders ? '/' || '/orders' : linkTo
          return (
            <NavLink to={to} key={link} className="navLink" activeClassName="navLinkselected">
              {link}
            </NavLink>
          )
        })}
      </nav>
    </div>
  )
}

export default NavBar
