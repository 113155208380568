/* eslint-disable camelcase */
import { AxiosResponse } from 'axios'
import API from 'src/api'
import { IUserResource, UserResource } from 'src/resources'
import { DetailResponse, ForgotPasswordValues, LoginValues, SignupValues } from 'src/types'

export type AuthServiceType = {
  login(user: LoginValues): Promise<DetailResponse<UserResource>>
  logout(): Promise<AxiosResponse<void>>
  signup(values: SignupValues): Promise<DetailResponse<UserResource>>
  resetPassword(values: ForgotPasswordValues): Promise<AxiosResponse<void>>
}

export const AuthService: AuthServiceType = {
  async login(user): Promise<DetailResponse<UserResource>> {
    const data = await API<DetailResponse<IUserResource>>({
      url: `auth/login`,
      method: 'POST',
      data: user,
    })
    return {
      ...data,
      data: new UserResource(data.data),
    }
  },

  async logout() {
    return API({ url: 'logout', method: 'POST' })
  },

  async signup(values): Promise<DetailResponse<UserResource>> {
    const {
      organisation,
      billingEmail,
      firstName,
      lastName,
      email,
      peechoApiKey,
      peechoButtonKey,
      peechoSecretKey,
      password,
      address,
      addressLine2,
      zipCode,
      city,
      state,
      country,
    } = values

    const payload = {
      name: organisation,
      billing_email_address: billingEmail,
      peecho_merchant_api_key: peechoApiKey,
      peecho_button_key: peechoButtonKey,
      peecho_merchant_secret_key: peechoSecretKey,
      admin: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      },
      billing_address: {
        city,
        state,
        address_line_1: address,
        address_line_2: addressLine2,
        zip_code: zipCode,
        country_code: country,
      },
    }
    const data = await API<DetailResponse<IUserResource>>({
      url: `merchants/register`,
      method: 'POST',
      data: payload,
    })
    return {
      ...data,
      data: new UserResource(data.data),
    }
  },

  async resetPassword(values) {
    return API({ url: 'auth/reset-password', method: 'POST', data: values })
  },
}
