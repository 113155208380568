import { FC } from 'react'
import './Input.scss'

interface InputProps {
  className?: string
  id: string
  max?: number
  min?: number
  name: string
  placeholder?: string
  style?: {}
  type: string
  value?: string | number
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const DefaultInput: FC<InputProps> = ({
  className,
  id,
  max,
  min,
  name,
  placeholder,
  style,
  value,
  type,
  onBlur,
  onChange,
}) => (
  <input
    autoComplete="off"
    className={className}
    id={id}
    max={max}
    min={min}
    name={name}
    placeholder={placeholder}
    style={style}
    type={type}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
  />
)

export default DefaultInput
