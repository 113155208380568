import { FC } from 'react'
import { Icon } from 'src/components'
import './AccountCardWrapper.scss'

interface AccountCardWrapperProps {
  isAdmin: boolean
  section: 'user' | 'merchant' | 'team'
  toggleModal: () => void
}

const AccountCardWrapper: FC<AccountCardWrapperProps> = ({
  children,
  toggleModal,
  section,
  isAdmin,
}) => {
  const content = {
    user: {
      title: 'Profile information',
      subTitle: 'Account details such as profile picture, name, address',
    },
    merchant: {
      title: 'Company information',
      subTitle: 'Company details such as name and billing information',
    },
    team: {
      title: 'Team members',
      subTitle: 'Edit add or remove team members',
    },
  }

  let info = content.user

  switch (section) {
    case 'merchant':
      info = content.merchant
      break
    case 'team':
      info = content.team
      break
  }

  const enableEdit = isAdmin || section === 'user'

  return (
    <div className="accountCardWrapper">
      <div className="accountCardHeader">
        <div className="generalDescription">
          <div className="accountCardTitle">{info.title}</div>
          <div>{info.subTitle}</div>
        </div>
        {enableEdit && (
          <div className="editIcon" onClick={toggleModal}>
            <Icon type="edit" />
            <div className="text">Edit</div>
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default AccountCardWrapper
