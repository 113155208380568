import { Field, FormikHandlers } from 'formik'
import { FC } from 'react'
import './CheckBox.scss'

export interface CheckBoxProps {
  name: string
  label?: string
  value?: boolean
  id: string
  onChange?: FormikHandlers['handleChange'] | (() => Promise<void>)
}

export const CheckBox: FC<CheckBoxProps> = ({ name, value, id, onChange, label }) => {
  const textStyle = {
    fontSize: 12,
    marginRight: 5,
  }
  return (
    <label htmlFor={id} className="checkboxWrapper" >
      <Field
        type="checkbox"
        name={name}
        value={value}
        id={id}
        checked={value}
        onChange={onChange}
        autoComplete="off"
      />
      <span className="checkmark"></span>
      {label && <span style={textStyle}>{label}</span>}
    </label>
  )
}

export default CheckBox
