import { CSSProperties, FC } from 'react'
import { greenColor, warningColor } from 'src/styles'
import { Button } from '../Button'
import './Modal.scss'

interface ModalProps {
  title: string
  error?: string
  successMessage?: string
  toggleModal: () => void
  isDisabled?: boolean
  onSubmit?: () => void
}

const Modal: FC<ModalProps> = ({
  toggleModal,
  children,
  title,
  isDisabled,
  onSubmit,
  error,
  successMessage,
}) => {
  const submit = () => {
    toggleModal()
  }

  const hasMessage = successMessage || error
  const messageStyle = {
    color: error ? warningColor : greenColor,
    textAlign: 'end',
    marginTop: 5,
  } as CSSProperties
  return (
    <div className="modalOverlay">
      <div className="modal">
        <div className="modalHeader">
          <div className="modalHeaderTitle"> {title}</div>
          <div className="modalHeaderClose" onClick={toggleModal}>
            Close
          </div>
        </div>
        <span />
        {children}
        <>
          {hasMessage && <div style={messageStyle}>{error || successMessage}</div>}
          {onSubmit && (
            <div className="modalButtons">
              <Button text="Cancel" onClick={toggleModal} theme="light" />
              <Button
                type="submit"
                onClick={onSubmit || submit}
                text="Save changes"
                disabled={!!isDisabled}
              />
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export default Modal
