import { CSSProperties, FC } from 'react'
import { Icon } from 'src/components'
import { primaryColor } from 'src/styles'
import './Button.scss'

export interface ButtonProps {
  disabled?: boolean
  bold?: boolean
  text: string
  icon?: string
  theme?: 'dark' | 'light' | 'transparent'
  type?: 'button' | 'submit' | 'reset' | undefined
  mb?: number
  mt?: number
  width?: number
  height?: number
  onClick?: () => void
  isLoading?: boolean
}

export const Button: FC<ButtonProps> = ({
  disabled,
  bold,
  icon,
  text,
  theme,
  type,
  onClick,
  height,
  mt,
  mb,
  width,
  isLoading,
}) => {
  const buttonType = type || 'button'
  const themeClass = theme ? theme : 'dark'
  const buttonClass = `Button ${themeClass}`
  const isTransparent = theme === 'transparent'

  const style: CSSProperties = {
    marginTop: mt || 10,
    marginBottom: mb || 17,
    fontWeight: bold ? 700 : 500,
    height: height || 39,
    minWidth: width || 100,
    border: isTransparent ? 'none' : `1px solid ${primaryColor}`,
  }

  return (
    <button
      type={buttonType}
      className={buttonClass}
      disabled={!!disabled || !!isLoading}
      onClick={onClick}
      style={style}>
      {icon && <Icon type={icon} />}

      {isLoading ? <span>Loading</span> : text}
    </button>
  )
}

export default Button
