import { FC } from 'react'
import { useAuthState } from 'src/contexts'
import { Banner } from '../../Banner'
import { NavBar } from '../../NavBar'
import './InnerWrapper.scss'

const AuthWrapper: FC = ({ children }) => {
  const { user } = useAuthState()
  return (
    <>
      <div className="innerWrapper">
        <div className="appWrapper">
          {user?.fullName && <NavBar userName={user?.fullName} />}
          <div className="pageWrapper">{children}</div>
        </div>
      </div>
      <Banner />
    </>
  )
}

export default AuthWrapper
