/* eslint-disable camelcase */
import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Button, countryOptions, Input } from 'src/components'
import { getMinCharsWarning } from 'src/helpers'
import { sleep } from 'src/helpers/sleep'
import { UserResource } from 'src/resources'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { SignupValues } from 'src/types'
import * as Yup from 'yup'
import './SignupForm.scss'

const organisationText = 'Organisation'
const peechoButtonKeyText = 'Peecho button key'
const peechoKeyText = 'Peecho api-key'
const peechoSecretKeyText = 'Peecho secret key'
const billingEmailText = 'Billing email address'
const emailText = 'Email address'
const addressText = 'Billing address'
const addressLine2Text = `${addressText} line 2`
const cityText = 'City'
const zipCodeText = 'Zip code'
const stateText = 'Province/State'
const countryText = 'country'
const passwordText = 'Password'
const passwordConfirmationText = 'Repeat password'
const requiredFieldText = 'This field is required.'

interface FormProps {
  signup: (values) => Promise<UserResource>
}

const SignupForm: FC<FormProps> = ({ signup }) => {
  const history = useHistory()

  const initialValues: SignupValues = {
    organisation: '',
    billingEmail: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    address: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
    peechoApiKey: '',
    peechoButtonKey: '',
    peechoSecretKey: '',
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(2, getMinCharsWarning('First name', 2)).required(requiredFieldText),
    lastName: Yup.string().min(2, getMinCharsWarning('Last name', 2)).required(requiredFieldText),
    organisation: Yup.string()
      .min(2, getMinCharsWarning('Organisation', 2))
      .required(requiredFieldText),
    peechoApiKey: Yup.string()
      .min(5, getMinCharsWarning(peechoKeyText, 5))
      .required(requiredFieldText),
    peechoButtonKey: Yup.string().min(5, getMinCharsWarning(peechoButtonKeyText, 5)).required(),
    peechoSecretKey: Yup.string().nullable(),
    billingEmail: Yup.string().email('Invalid email').required(requiredFieldText),
    email: Yup.string().email('Invalid email').required(requiredFieldText),
    address: Yup.string().required(requiredFieldText),
    zipCode: Yup.string().required(requiredFieldText),
    city: Yup.string().required(requiredFieldText),
    state: Yup.string().required(requiredFieldText),
    country: Yup.string().required(requiredFieldText),
    password: Yup.string()
      .min(8, getMinCharsWarning(passwordText, 8))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password must contain an uppercase, a lowercase, a number and one special character',
      )
      .required(requiredFieldText),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(requiredFieldText),
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const onSubmit = async (values: SignupValues) => {
    if (!signup) return

    setErrorMessage(undefined)
    setIsSubmitting(true)
    try {
      // This goes way too fast
      await sleep(300)
      await signup(values)
      history.push('/welcome')
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleChange, handleBlur, values, isValid, handleSubmit }) => (
        <Form
          className="signUpForm"
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}>
          <div className="signupRows">
            <Input
              label={organisationText}
              type="text"
              name="organisation"
              id="organisation"
              value={values.organisation}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Name of your organisation"
            />
            <Input
              label={billingEmailText}
              type="text"
              name="billingEmail"
              id="billingEmail"
              value={values.billingEmail}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={billingEmailText}
            />
            <Input
              label="Your name"
              type="text"
              name="firstName"
              id="firstName"
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="First name"
            />
            <Input
              label="Your last name"
              type="text"
              name="lastName"
              id="lastName"
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Last name"
            />
            <Input
              label={emailText}
              type="text"
              name="email"
              id="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={emailText}
            />
            <Input
              label={peechoKeyText}
              type="text"
              name="peechoApiKey"
              id="peechoApiKey"
              value={values.peechoApiKey}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={peechoKeyText}
            />
            <Input
              label={peechoButtonKeyText}
              type="text"
              name="peechoButtonKey"
              id="peechoButtonKey"
              value={values.peechoButtonKey}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={peechoButtonKeyText}
            />
            <Input
              label={peechoSecretKeyText}
              type="text"
              name="peechoSecretKey"
              id="peechoSecretKey"
              value={values.peechoSecretKey ?? ''}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={peechoSecretKeyText}
            />
            <Input
              label={addressText}
              type="text"
              name="address"
              id="address"
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={addressText}
            />
            <Input
              label={addressLine2Text}
              type="text"
              name="addressLine2"
              id="addressLine2"
              value={values.addressLine2 || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={addressLine2Text}
            />
            <Input
              label={cityText}
              type="text"
              name="city"
              id="city"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={cityText}
            />
            <Input
              label={zipCodeText}
              type="text"
              name="zipCode"
              id="zipCode"
              value={values.zipCode}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={zipCodeText}
            />
            <Input
              label={stateText}
              type="text"
              name="state"
              id="state"
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={stateText}
            />
            <Input
              label="Country"
              type="text"
              name={countryText}
              id={countryText}
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={countryText}
              options={countryOptions}
            />
            <Input
              label={passwordText}
              type="password"
              name="password"
              id="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={passwordText}
            />

            <Input
              label={passwordConfirmationText}
              type="password"
              name="passwordConfirmation"
              id="passwordConfirmation"
              value={values.passwordConfirmation}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={passwordConfirmationText}
            />
          </div>
          {errorMessage ? <p className="errorText">{errorMessage}</p> : null}
          <footer>
            <Button
              type="submit"
              disabled={!isValid}
              text="Create my account"
              isLoading={isSubmitting}
            />
            <NavLink to="/login" className="loginLink newAccountLink">
              Back to login
            </NavLink>
          </footer>
        </Form>
      )}
    </Formik>
  )
}

export default SignupForm
