import { CSSProperties, FC, useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { warningColor } from 'src/styles'
import { Icon } from '../Icon'
import './DropZone.scss'

export interface DropZoneProps {
  message?: string
  logo?: File | string
  previewImage?: File
  setFile: (file: File) => void
  error?: string
}

export const DropZone: FC<DropZoneProps> = ({ message, logo, previewImage, setFile, error }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  })

  const preview = useMemo(() => {
    if (previewImage) {
      return URL.createObjectURL(previewImage)
    } else if (logo) {
      return typeof logo === 'object' ? URL.createObjectURL(logo) : logo
    }

    return ''
  }, [logo, previewImage])

  const textStyle = {
    color: 'black',
    fontSize: 10,
    textAlign: 'center',
  } as CSSProperties

  const messageText = message || 'Upload your file here'
  const backgroundImage = `url(${preview})`
  return (
    <div className="DropZoneWrapper">
      <div
        className="dropArea"
        {...getRootProps()}
        style={{
          backgroundImage,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}>
        <input {...getInputProps()} />
        {!preview && (
          <>
            <Icon color="grey200" type="addPhoto" />
            <div style={textStyle}>{messageText}</div>
          </>
        )}
      </div>
      <div style={{ color: warningColor, fontSize: 13, marginTop: 10 }}> {error}</div>
    </div>
  )
}

export default DropZone
