import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import { LayoutResource } from 'src/resources'
import { TemplateService } from 'src/services'

interface TemplateContextProps {
  isLoading: boolean
  templateLayouts: LayoutResource[] | undefined
  templatePreview: string | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  setTemplateLayouts: Dispatch<SetStateAction<undefined | LayoutResource[]>>
  togglePageLayout: (layoutId: number, isEnabled: boolean) => Promise<LayoutResource[] | undefined>
  getTemplateLayouts: () => Promise<LayoutResource[] | undefined>
  getBookViewerSample(): Promise<string>
}

const TemplateContext = createContext<Partial<TemplateContextProps>>({})

const TemplateProvider: FC<ReactNode> = ({ children }) => {
  const [templateLayouts, setTemplateLayouts] = useState<undefined | LayoutResource[]>()
  const [isLoading, setLoading] = useState(false)
  const [templatePreview, setTemplatePreview] = useState<string | undefined>(undefined)

  const getTemplateLayouts = async () => {
    setLoading(true)
    try {
      const { data } = await TemplateService.getTemplateLayouts()
      setTemplateLayouts(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const getBookViewerSample = async () => {
    setLoading(true)
    try {
      const data = await TemplateService.getBookViewerSample()
      setTemplatePreview(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const togglePageLayout = async (layoutId, isEnabled) => {
    setLoading(true)
    try {
      const { data } = await TemplateService.togglePageLayout(layoutId, isEnabled)
      setTemplateLayouts(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const context = {
    isLoading,
    templateLayouts,
    templatePreview,
    getTemplateLayouts,
    setLoading,
    togglePageLayout,
    getBookViewerSample,
  }

  return <TemplateContext.Provider value={context}>{children}</TemplateContext.Provider>
}

const useTemplateState = (): Partial<TemplateContextProps> => {
  const context = useContext(TemplateContext)
  if (context === undefined) {
    throw new Error('useTemplateState must be used within a TemplateContext')
  }
  return context
}

export { TemplateProvider, useTemplateState }
