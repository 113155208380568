import { useField } from 'formik'
import { FC } from 'react'
import DefaultInput from './DefaultInput'
import { DropDownInput } from './DropDownInput'
import './Input.scss'

export interface InputProps {
  placeholder?: string
  label?: string
  name: string
  type: string
  value: string
  id: string
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLElement>) => void
  options?: { value: string; label: string }[] | undefined
}

export const Input: FC<InputProps> = ({
  label,
  name,
  type,
  placeholder,
  value,
  id,
  options,
  onBlur,
  onChange,
}) => {
  const [, meta] = useField(name)
  const { error, touched } = meta
  const showError = touched && error
  const className = `input ${showError ? 'errorInput' : ''}`
  return (
    <label className="inputWrapper">
      {label}
      {options ? (
        <DropDownInput
          type={type}
          name={name}
          value={value}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          className={className}
          options={options}
          placeholder={placeholder}
        />
      ) : (
        <DefaultInput
          type={type}
          name={name}
          placeholder={placeholder ?? ''}
          value={value}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          className={className}
        />
      )}
      {showError && <div className="errorText">{error}</div>}
    </label>
  )
}

export default Input
