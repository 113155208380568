import { FC, useState } from 'react'
import { TemplateResource } from 'src/resources/template/TemplatesResource'
import { backgroundColor, templateColor } from 'src/styles'
import { BrandingWrapper } from '../BrandingWrapper'
import { ChapterPagePreview, CoverLayoutSelect, CoverPreview } from './components'

interface PreviewCardProps {
  template: TemplateResource
  logoUrl?: string | undefined
}

const PreviewCard: FC<PreviewCardProps> = ({ template, logoUrl }) => {
  const {
    logo_position,
    logo_size,
    cover_layout,
    has_cover_title,
    cover_color,
    cover_text_color,
    page_color,
    page_text_color,
    chapter_cover_color,
    chapter_cover_text_color,
    has_spine_title,
    has_cover_logo,
    // has_spine_logo,
    layouts,
    font,
  } = template

  const [coverLayoutId, setCoverLayout] = useState(cover_layout?.id)
  const [coverLayoutError, setCoverLayoutError] = useState(undefined)

  const layoutOptions = layouts.filter((layout) => layout.is_cover === true)
  const layoutOpstionsIds = layoutOptions.map((option) => option.id)

  const coverLayoutText = 'Cover of the photobook can have multiple layouts. Select one below.'
  const fontFamily = font && font.family
  const borderColor = cover_color === '#ffffff' ? templateColor : backgroundColor
  const bookBorder = `1px solid ${borderColor}`

  return (
    <BrandingWrapper
      isBig
      title="Cover preview"
      error={coverLayoutError}
      instructions="Size of the book is A4 Landscape (210mm by 294mm)"
      className="cover-wrapper">
      <div>
        <p style={{ fontSize: 11 }}>{coverLayoutText}</p>
        <CoverLayoutSelect
          coverLayoutId={coverLayoutId}
          options={layoutOpstionsIds}
          setCoverLayout={setCoverLayout}
          setCardError={setCoverLayoutError}
        />
        <CoverPreview
          logoUrl={logoUrl}
          logoPosition={logo_position}
          logoSize={logo_size}
          hasCoverTitle={has_cover_title}
          coverColor={cover_color}
          bookBorder={bookBorder}
          coverTextColor={cover_text_color}
          hasSpineTitle={has_spine_title}
          hasCoverLogo={has_cover_logo}
          // hasSpineLogo={has_spine_logo}
          fontFamily={fontFamily}
          coverLayoutId={coverLayoutId}
        />

        <ChapterPagePreview
          coverColor={cover_color}
          chapterCoverColor={chapter_cover_color}
          chapterCoverTextColor={chapter_cover_text_color}
          fontFamily={fontFamily}
          pageColor={page_color}
          pageTextColor={page_text_color}
          bookBorder={bookBorder}
        />
      </div>
    </BrandingWrapper>
  )
}

export default PreviewCard
