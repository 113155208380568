import { CSSProperties, FC } from 'react'
import './ChapterPagePreview.scss'

interface ChapterPagePreviewProps {
  bookBorder: string
  chapterCoverColor: string
  chapterCoverTextColor: string
  coverColor: string
  fontFamily: string
  pageColor: string
  pageTextColor: string
}

const ChapterPagePreview: FC<ChapterPagePreviewProps> = ({
  bookBorder,
  chapterCoverColor,
  chapterCoverTextColor,
  coverColor,
  fontFamily,
  pageColor,
  pageTextColor,
}) => {
  const markdownFontStyle = { fontSize: 9, lineHeight: 1.2 }

  const markdownStyle = {
    display: 'flex',
    flexDirection: 'column',
  } as CSSProperties

  const pageLayoutStyle = {
    backgroundColor: pageColor,
    color: pageTextColor,
    fontFamily: fontFamily,
    justifyContent: 'center',
    alignItems: 'center',
    border: bookBorder,
  }

  const chapterCoverStyle = {
    backgroundColor: chapterCoverColor,
    color: chapterCoverTextColor,
    fontFamily,
    border: bookBorder,
  } as CSSProperties

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div
        className="chapterPageContainer"
        style={{ backgroundColor: coverColor, border: bookBorder }}>
        <div className="chapterPagePreview" style={chapterCoverStyle}>
          <div style={markdownStyle}>
            <div style={{ fontSize: 12, paddingBottom: 10, fontWeight: 'bold' }}>Title</div>
            <div style={markdownFontStyle}>description</div>
            <div style={markdownFontStyle}>notes</div>
            <div style={markdownFontStyle}>all markdown options are supported</div>
          </div>
          <div className="chapterPreviewLandscapeWrapper">
            <div className="chapterPreviewLandscape"></div>
            <div className="chapterPreviewLandscape"></div>
          </div>
        </div>
        <div className="chapterPagePreview" style={pageLayoutStyle}>
          <div className="pagePreviewLandscape"></div>
        </div>
      </div>
    </div>
  )
}

export default ChapterPagePreview
