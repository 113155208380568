import API from 'src/api'
import { IOrderResource, OrderResource } from 'src/resources'
import { IndexResponse } from 'src/types'

export type OrdersServiceType = {
  getOrders(id: number | undefined): Promise<IndexResponse<OrderResource>>
}

const baseUrl = '/merchants'

export const OrdersService: OrdersServiceType = {
  async getOrders(id) {
    const data = await API<IndexResponse<IOrderResource>>({
      url: `${baseUrl}/${id}/orders`,
      method: 'GET',
    })
    return {
      ...data,
      data: data.data.map((i) => new OrderResource(i)),
    }
  },
}
