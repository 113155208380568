import { FC } from 'react'
import { Wrapper } from 'src/components'
import { useAuthState } from 'src/contexts'
import { SignupForm } from './components'

const SignupPage: FC = () => {
  const { signup } = useAuthState()
  const title = 'Hi, nice you want to join'
  const entry = 'We need some information for creating your account'
  return (
    <Wrapper title={title} entry={entry}>
      { signup ? <SignupForm signup={signup} /> : null }
    </Wrapper>
  )
}

export default SignupPage
