import { CSSProperties, FC } from 'react'
import { backgroundColor, borderColor } from 'src/styles'
import ImagePlaceholder from './ImagePlaceholder'
import TextLinePlaceholder from './TextLinePlaceholder'
interface ImagePagePlaceholderProps {
  type:
    | string
    | 'columns'
    | 'column'
    | 'columnAndLandscapes'
    | 'columnAndLandscape'
    | 'landscapes'
    | 'landscape'
  flip?: boolean
  hasTitle?: boolean
  color?: string
}

const ImagePagePlaceholder: FC<ImagePagePlaceholderProps> = ({ type, flip, hasTitle, color }) => {
  const flexDirection = `row${flip ? '-reverse' : ''}`

  const placeholderStyle = {
    display: 'flex',
    flexDirection,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 80,
    height: 60,
    background: backgroundColor,
    flexWrap: 'wrap',
    border: `1px solid ${color || borderColor}`,
    borderRadius: 5,
  } as CSSProperties

  const width = 70
  const height = 22.5

  let placeholder = <ImagePlaceholder color={color} width={width} />

  switch (type) {
    case 'columns':
      placeholder = (
        <>
          <ImagePlaceholder color={color} />
          <ImagePlaceholder color={color} />
        </>
      )

      break
    case 'landscapes':
      placeholder = (
        <>
          <ImagePlaceholder color={color} height={height} />
          <ImagePlaceholder color={color} height={height} />
          <ImagePlaceholder color={color} height={height} />
          <ImagePlaceholder color={color} height={height} />
        </>
      )
      break
    case 'columnAndLandscapes':
      placeholder = (
        <>
          <ImagePlaceholder color={color} />
          <div>
            <ImagePlaceholder color={color} height={height} mb={5} />
            <ImagePlaceholder color={color} height={height} />
          </div>
        </>
      )
      break
    case 'columnAndLandscape':
      placeholder = (
        <>
          <ImagePlaceholder color={color} height={height} />
          <ImagePlaceholder color={color} />
        </>
      )
      break
    case 'column':
      placeholder = <ImagePlaceholder color={color} />
      break
    case 'landscape':
      placeholder = <ImagePlaceholder color={color} width={width} />
      break
  }

  return (
    <div style={placeholderStyle}>
      {placeholder}
      {hasTitle && <TextLinePlaceholder color={color} />}
    </div>
  )
}

export default ImagePagePlaceholder
