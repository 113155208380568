import Cookies from 'js-cookie'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react'
import { UserResource } from 'src/resources'
import { AuthService, UserService } from 'src/services'
import { ForgotPasswordValues, PasswordFormValues, UserFormValues } from 'src/types'

interface AuthContextProps {
  isAuthenticated: boolean
  isLoading: boolean
  isInitialLoading: boolean
  user: UserResource | undefined
  token: string | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  brand: string
  setUser: Dispatch<SetStateAction<undefined | UserResource>>
  login: (values) => Promise<UserResource | void>
  signup: (values) => Promise<UserResource>
  logout: () => void
  resetPassword: (value: ForgotPasswordValues) => Promise<void>
  getMe: () => Promise<UserResource | undefined>
  editMe: (info: UserFormValues) => Promise<UserResource | undefined>
  updatePassword: (info: PasswordFormValues) => Promise<UserResource | undefined>
}

const AuthContext = createContext<Partial<AuthContextProps>>({})

const AuthProvider: FC<ReactNode> = ({ children }) => {
  const [user, setUser] = useState<undefined | UserResource>()
  const [token, setToken] = useState<string | undefined>('')
  const [isLoading, setLoading] = useState(false)
  const [isInitialLoading, setInitialLoading] = useState(true)

  const isAuthenticated = useMemo(() => !!token, [token])

  const brand = 'Oneclicklayout'

  const signup = async (values) => {
    setLoading(true)
    try {
      const { data } = await AuthService.signup(values)
      return data
    } finally {
      setLoading(false)
    }
  }

  const login = async (values) => {
    try {
      const { data } = await AuthService.login(values)
      if (data.accessToken) {
        Cookies.set('@token', data.accessToken)
      }
      setUser(data)
      setToken(data.accessToken)
      return data
    } finally {
      setLoading(false)
    }
  }

  const logout = () => {
    setToken(undefined)
    setUser(undefined)
  }

  const resetPassword = async (values) => {
    try {
      await AuthService.resetPassword(values)
      return
    } finally {
      setLoading(false)
    }
  }

  const getMe = async () => {
    setLoading(true)
    try {
      const { data } = await UserService.getMe()
      setUser(data)
      setToken(Cookies.get('@token'))
      return data
    } finally {
      setInitialLoading(false)
      setLoading(false)
    }
  }

  const editMe = async (info) => {
    setLoading(true)
    try {
      const { data } = await UserService.editMe(info)
      setUser(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const updatePassword = async (info) => {
    setLoading(true)
    try {
      const { data } = await UserService.updatePassword(info)
      return data
    } finally {
      setLoading(false)
    }
  }

  const context = {
    isAuthenticated,
    isLoading,
    token,
    user,
    brand,
    login,
    logout,
    resetPassword,
    setLoading,
    setUser,
    signup,
    getMe,
    isInitialLoading,
    editMe,
    updatePassword,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

const useAuthState = (): Partial<AuthContextProps> => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthContext')
  }
  return context
}

export { AuthProvider, useAuthState }
