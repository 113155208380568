import { FC, useState } from 'react'
import { useMerchantState } from 'src/contexts'
import { TemplateResource } from 'src/resources/template/TemplatesResource'
import { BrandingWrapper } from '../BrandingWrapper'
import './ColorCard.scss'
import { ColorPickerInput } from './components'

interface ColorCardProps {
  template: TemplateResource
}

const ColorCard: FC<ColorCardProps> = ({ template }) => {
  const { cover_color, cover_text_color, page_color, chapter_cover_color,  chapter_cover_text_color} = template
  const { editTemplate } = useMerchantState()
  const [cardError, setError] = useState<string>('')
  const [coverColor, setCoverColor] = useState(cover_color)
  const [coverTextColor, setCoverTextColor] = useState(cover_text_color)
  const [ chapterCoverColor, setChapterCoverColor] = useState(chapter_cover_color)
  const [chapterCoverTextColor, setChapterCoverTextColor] = useState(chapter_cover_text_color)
  const [pageColor, setPageColor] = useState(page_color)
  // const [pageTextColor, setPageTextColor] = useState(page_text_color)

  const instructions = 'Select and change colors for your prints'

  return (
    <BrandingWrapper title="Color palette" instructions={instructions} error={cardError} className="colors-wrapper">
      <div className="ColorPaletteWrapper">
        <ColorPickerInput
          label="Cover"
          name="cover_color"
          value={coverColor}
          editTemplate={editTemplate}
          setColor={setCoverColor}
          setError={setError}
        />
        <ColorPickerInput
          label="Cover text"
          name="cover_text_color"
          value={coverTextColor}
          editTemplate={editTemplate}
          setColor={setCoverTextColor}
          setError={setError}
        />
        <ColorPickerInput
          label="Chapter cover"
          name="chapter_cover_color"
          value={chapterCoverColor}
          editTemplate={editTemplate}
          setColor={setChapterCoverColor}
          setError={setError}
        />
        <ColorPickerInput
          label="Chapter cover text"
          name="chapter_cover_text_color"
          value={chapterCoverTextColor}
          editTemplate={editTemplate}
          setColor={setChapterCoverTextColor}
          setError={setError}
        />
        <ColorPickerInput
          label="Pages"
          name="page_color"
          value={pageColor}
          editTemplate={editTemplate}
          setColor={setPageColor}
          setError={setError}
        />
        {/* Uncomment once we add possibility to have text under pictures */}
        {/* <ColorPickerInput
          label="Pages text"
          name="page_text_color"
          value={pageTextColor}
          editTemplate={editTemplate}
          setColor={setPageTextColor}
          setError={setError}
        /> */}
      </div>
    </BrandingWrapper>
  )
}

export default ColorCard
