import { FC } from 'react'
import { primaryColor } from 'src/styles'
import AddPhotoIcon from './AddPhotoIcon'
import AddUserIcon from './AddUserIcon'
import ArrowIcon from './ArrowIcon'
import DeleteIcon from './DeleteIcon'
import EditIcon from './EditIcon'
import Logo from './Logo'
import PhotoIcon from './PhotoIcon'
import PlusIcon from './PlusIcon'
import SpineArrow from './SpineArrow'
import UserIcon from './UserIcon'

export interface IconsProps {
  color?: string
  deg?: number
  width?: number
  height?: number
  strokeWidth?: number
}

export interface Props extends IconsProps {
  direction?: 'up' | 'down' | 'left' | 'right'
  rotate?: number
  type: string
  onClick?: () => void
  disabled?: boolean | undefined
  mr?: number
  mt?: number
  mb?: number
  ml?: number
  margin?: string
}

const Icon: FC<Props> = ({
  color,
  direction,
  type,
  rotate,
  disabled = false,
  width,
  height,
  strokeWidth,
  mr,
  mt,
  mb,
  ml,
  margin,
  onClick,
}) => {
  let deg = 0

  switch (direction) {
    case 'up':
      deg = 180
      break
    case 'left':
      deg = 90
      break
    case 'right':
      deg = -90
      break
    case 'down':
      deg = 0
      break
  }

  const fillColor = color || primaryColor

  const style = {
    marginRight: mr && `${mr}px`,
    marginLeft: ml && `${ml}px`,
    marginTop: mt && `${mt}px`,
    marginBottom: mb && `${mb}px`,
    margin: margin && margin,
  }

  let icon = <></>
  switch (type) {
    case 'addPhoto':
      icon = <AddPhotoIcon color={fillColor} />
      break
    case 'addUser':
      icon = <AddUserIcon color={fillColor} />
      break
    case 'arrow':
      icon = <ArrowIcon color={fillColor} deg={deg} />
      break
    case 'delete':
      icon = <DeleteIcon color={fillColor} />
      break
    case 'edit':
      icon = <EditIcon color={fillColor} />
      break
    case 'logo':
      icon = <Logo color={fillColor} />
      break
    case 'photo':
      icon = <PhotoIcon color={fillColor} />
      break
    case 'plus':
      icon = (
        <PlusIcon
          color={fillColor}
          deg={rotate}
          width={width}
          height={height}
          strokeWidth={strokeWidth}
        />
      )
      break
    case 'spine':
      icon = <SpineArrow deg={deg} />
      break
    case 'user':
      icon = <UserIcon color={fillColor} />
      break
  }

  return onClick ? (
    <button onClick={onClick} disabled={disabled} style={style}>
      {icon}
    </button>) : <>{icon}</>
}

export default Icon
