import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Wrapper } from 'src/components'
import { useAuthState } from 'src/contexts'
import './WelcomePage.scss'

const WelcomePage: FC = () => {
  const { brand } = useAuthState()
  const title = `Thanks for joining ${brand}`
  const entry = 'We will send you a confirmation link to you e-mail address'

  return (
    <Wrapper title={title} entry={entry}>
      <NavLink to="/login" className="backToLoginLink">
        Back to login
      </NavLink>
    </Wrapper>
  )
}

export default WelcomePage
