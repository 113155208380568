import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Input, Wrapper } from 'src/components'
import { useAuthState } from 'src/contexts'
import { sleep } from 'src/helpers/sleep'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { greenColor } from 'src/styles'
import { ForgotPasswordValues } from 'src/types'
import * as Yup from 'yup'
import './ForgotPassword.scss'

const ForgotPasswordPage: FC = () => {
  const { resetPassword } = useAuthState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()
  const [successMessage, setSuccesccMessage] = useState<undefined | string>()

  const title = 'Please fill in your e-mail'
  const entry = 'We will send you an email to reset your password'

  const initialValues: ForgotPasswordValues = { email: '' }
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('This field is required.'),
  })
  const emailText = 'Email address'

  const onSubmit = async (values: ForgotPasswordValues) => {
    if (!resetPassword) return
    setErrorMessage(undefined)
    setIsSubmitting(true)
    try {
      // This goes way too fast
      await sleep(300)
      await resetPassword(values)
      setSuccesccMessage('We have sent a temporary password to your email')
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Wrapper title={title} entry={entry}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleChange, handleBlur, values, isValid, handleSubmit }) => {
          return (
            <Form
              className="forgotPasswordForm"
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
              }}>
              <Input
                label={emailText}
                type="text"
                name="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={emailText}
              />
              {errorMessage && <p className="errorText">{errorMessage}</p>}
              {successMessage && (
                <p style={{ color: greenColor, fontSize: 'bold' }}>{successMessage}</p>
              )}
              <footer>
                <Button
                  type="submit"
                  disabled={!isValid}
                  text="Send email"
                  isLoading={isSubmitting}
                />
                <NavLink to="/login" className="loginLink">
                  Back to login
                </NavLink>
              </footer>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

export default ForgotPasswordPage
