import { FC } from 'react'
import { LoadingAnimation, Wrapper } from 'src/components'
import { useMerchantState } from 'src/contexts'
import './Branding.scss'
import { ColorCard, FontCard, LayoutCard, LogoCard, PreviewCard } from './components'

const BrandingPage: FC = () => {
  const { merchant, template, editLogo } = useMerchantState()
  const merchantTemplate = template || merchant?.template
  return (
    <Wrapper>
      {merchant && merchantTemplate ? (
        <div className="BrandingWrapper">
          <div className="previewCardWrapper">
            <PreviewCard template={merchantTemplate} logoUrl={merchant?.logoUrl} />
            <LogoCard
              isCover
              uploadLogo={editLogo}
              merchantId={merchant.id}
              logoUrl={merchant.logoUrl}
            />
            {/* Uncomment once SPINE_LOGO is implemented in BE */}
            {/* <LogoCard
                uploadLogo={editLogo}
                merchantId={merchant.id}
                logoUrl={merchant.spineLogoUrl || merchant.logoUrl}
              /> */}
            <LayoutCard merchantTemplate={merchantTemplate} />
            <ColorCard template={merchantTemplate} />
            {merchantTemplate.font && merchantTemplate.font.id && (
              <FontCard currentFontId={merchantTemplate.font.id} />
            )}
          </div>
        </div>
      ) : (
        <LoadingAnimation />
      )}
    </Wrapper>
  )
}

export default BrandingPage
