import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import { MerchantResource, OrderResource, OrdersResource } from 'src/resources'
import { TemplateResource } from 'src/resources/template/TemplatesResource'
import { MerchantService, OrdersService } from 'src/services'
import { EditMerchantValues, EditTemplateValues, UserFormValues } from 'src/types'

interface MerchantContextProps {
  isLoading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  merchant: MerchantResource | undefined
  apiKey: string | undefined
  template: TemplateResource | undefined
  orders: OrdersResource
  setApiKey: Dispatch<SetStateAction<undefined | string>>
  setMerchant: Dispatch<SetStateAction<undefined | MerchantResource>>
  getMerchant: () => Promise<MerchantResource | undefined>
  getOrders: (id: number | undefined) => Promise<OrderResource[]>
  editMerchant: (id: number, info: EditMerchantValues) => Promise<MerchantResource | undefined>
  editLogo: (id: number, info, isCover?: boolean) => Promise<MerchantResource | undefined>
  editTemplate: (info: EditTemplateValues) => Promise<TemplateResource | undefined>
  addUser: (values: UserFormValues) => Promise<MerchantResource | undefined>
  updateUserRole: (
    id: number,
    userId: number,
    role: string,
  ) => Promise<MerchantResource | undefined>
  deleteUser: (id: number) => Promise<MerchantResource | undefined>
  generateApiKey: (id: number, role?: string) => Promise<{ api_key: string }>
}

const MerchantContext = createContext<Partial<MerchantContextProps>>({})

const MerchantProvider: FC<ReactNode> = ({ children }) => {
  const [merchant, setMerchant] = useState<undefined | MerchantResource>()
  const [template, setTemplate] = useState<undefined | TemplateResource>()
  const [apiKey, setApiKey] = useState<string>('')
  const [orders, setOrders] = useState<undefined | OrdersResource>()
  const [isLoading, setLoading] = useState(false)

  const getMerchant = async () => {
    setLoading(true)
    try {
      const { data } = await MerchantService.getMerchant()
      setMerchant(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const addUser = async (values) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.addUser(values)
      setMerchant(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const deleteUser = async (id) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.deleteUser(id)
      setMerchant(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const editMerchant = async (id, info) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.editMerchant(id, info)
      setMerchant(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const updateUserRole = async (id, userId, role) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.updateUserRole(id, userId, role)
      setMerchant(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const editLogo = async (id, info, isCover) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.editLogo(id, info, isCover)
      setMerchant(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const editTemplate = async (info) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.editTemplate(info)
      setTemplate(data)
      return data
    } finally {
      setLoading(false)
    }
  }

  const generateApiKey = async (id, role) => {
    setLoading(true)
    try {
      const { data } = await MerchantService.generateApiKey(id, role)
      setApiKey(data.api_key)
      return data
    } finally {
      setLoading(false)
    }
  }

  const getOrders = async (id) => {
    setLoading(true)
    try {
      const { data } = await OrdersService.getOrders(id)
      setOrders({ orders: data })
      return data
    } finally {
      setLoading(false)
    }
  }

  const context = {
    isLoading,
    merchant,
    apiKey,
    template,
    orders,
    getOrders,
    setTemplate,
    setApiKey,
    setLoading,
    setMerchant,
    editMerchant,
    editTemplate,
    addUser,
    editLogo,
    deleteUser,
    getMerchant,
    generateApiKey,
    updateUserRole,
  }

  return <MerchantContext.Provider value={context}>{children}</MerchantContext.Provider>
}

const useMerchantState = (): Partial<MerchantContextProps> => {
  const context = useContext(MerchantContext)
  if (context === undefined) {
    throw new Error('useMerchantState must be used within a MerchantContext')
  }
  return context
}

export { MerchantProvider, useMerchantState }
