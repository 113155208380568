import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Icon, InitialsBadge } from 'src/components'
import { useAuthState } from 'src/contexts'
import { capitalize, sleep } from 'src/helpers'
import { isErrorResource, UserResource } from 'src/resources'
import { primaryColor } from 'src/styles'
import './TeamRow.scss'

interface TeamRowProps {
  user: UserResource
  successMessage?: string
  onEdit?: (user: UserResource) => void
  onDelete?: (user: UserResource) => void
  setErrorMessage?: Dispatch<SetStateAction<string | undefined>>
  setSuccessMessage?: Dispatch<SetStateAction<string | undefined>>
}

const TeamRow: FC<TeamRowProps> = ({
  user,
  onDelete,
  setSuccessMessage,
  setErrorMessage,
  onEdit,
}) => {
  const { user: me } = useAuthState()
  const isMe = !!me && me.id === user.id
  const isAdmin = !!me && me.isAdmin

  const [isDeleting, setIsDeleting] = useState(false)

  const showIcons = isAdmin && !isMe && onDelete

  const editUser = () => {
    if (!onEdit) return
    onEdit(user)
  }

  const deleteUser = async () => {
    if (isMe || !onDelete || !setSuccessMessage || !setErrorMessage) return
    setErrorMessage(undefined)
    setIsDeleting(true)
    try {
      await sleep(300)
      await onDelete(user)
      setSuccessMessage(`${user.fullName} has been deleted`)
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <>
      <div className="teamRowWrapper">
        <div className="badgeAndName">
          <InitialsBadge initials={user.initials} />
          <div>{user.fullName}</div>
        </div>
        <div className="emailAndRole">
          <div className="email">{user.email}</div>
          <div className="role">{capitalize(user.role)}</div>
        </div>
        {showIcons ? (
          <div className="icons">
            <Icon
              type="delete"
              onClick={deleteUser}
              disabled={isDeleting}
              mr={8}
              color={primaryColor}
            />
            {onEdit && <Icon type="edit" onClick={editUser} ml={8} />}
          </div>
        ) : (
          <div style={{ width: 45 }}></div>
        )}
      </div>
    </>
  )
}

export default TeamRow
