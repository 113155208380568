import { formatDateString } from 'src/helpers'

interface IExternalOrderResource {
  external_service: string
  external_order_id: number
}

class ExternalOrderResource {
  public service: string
  public id: number
  constructor(externalOrder: IExternalOrderResource) {
    this.service = externalOrder.external_service
    this.id = externalOrder.external_order_id
  }
}

type OrderStatus = 'COMPLETED' | 'CANCELLED' | 'CONFIRMED' | 'PENDING'

export interface IOrderResource {
  id: number
  value: number
  status: OrderStatus
  external_order?: IExternalOrderResource
  preview_urls: string[]
  created_at: string
  updated_at?: string
  // merchant: IMerchantResource
}

export class OrderResource {
  public id: number
  public value: number
  public status: OrderStatus
  public externalOrder: ExternalOrderResource | {}
  public previewUrls: string[]
  public createdAt?: string
  public updatedAt?: string

  constructor(order: IOrderResource) {
    this.id = order.id
    this.value = order.value
    this.status = order.status
    this.externalOrder = order.external_order || {}
    this.previewUrls = order.preview_urls
    this.createdAt = formatDateString(order.created_at)
    this.updatedAt = order.updated_at && formatDateString(order.updated_at)
  }
}

export interface IOrdersResource {
  orders: IOrderResource[]
}

export class OrdersResource {
  public orders: OrderResource[]

  constructor(orders: IOrdersResource) {
    this.orders = orders ? orders.orders.map((i) => new OrderResource(i)) : []
  }
}
