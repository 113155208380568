import { CSSProperties, FC, useState } from 'react'
import { LayoutResource } from 'src/resources'
import { backgroundColor, linkColor, primaryColor, warningColor } from 'src/styles'
import { PageLayoutSelect } from '../PageLayoutSelect'
import './TemplatesCard.scss'

interface TemplatesCardProps {
  options: LayoutResource[]
  isMTT?: boolean
}

const TemplatesCard: FC<TemplatesCardProps> = ({ options, isMTT }) => {
  const [error, setError] = useState<string | undefined>(undefined)

  const newFeatureStyle = {
    color: linkColor,
    fontSize: 10,
    fontWeight: 'normal',
    textAlign: 'center',
    paddingLeft: 10,
  } as CSSProperties

  const chapterCoverStyle = {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 80,
    height: 60,
    background: backgroundColor,
    border: `1px solid ${primaryColor}`,
    borderRadius: 5,
    fontSize: 11,
  }

  const errorStyle = {
    fontSize: 11,
    color: warningColor,
    marginLeft: 15,
    fontWeight: 400,
  }

  const chapterCoverImageStyle = {
    backgroundColor: primaryColor,
    borderRadius: 3,
    width: 33,
    height: 25,
    marginBottom: 0,
  }

  return (
    <div className="TemplateWrapper">
      <div className="templateDescription">
        Page layouts options {error && <span style={errorStyle}>{error}</span>}
      </div>
      <div className="templatesContainer">
        <PageLayoutSelect options={options} setError={setError} />
      </div>

      {!isMTT && (
        <>
          <div className="templateDescription">
            Chapter cover <span style={newFeatureStyle}>More options coming soon</span>
          </div>
          <div style={chapterCoverStyle}>
            <div>Markdown</div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: 80 }}>
              <div style={chapterCoverImageStyle}></div>
              <div style={chapterCoverImageStyle}></div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default TemplatesCard
