import API from 'src/api'
import { IUserResource, UserResource } from 'src/resources'
import { DetailResponse, PasswordFormValues, UserFormValues } from 'src/types'

export type UserServiceType = {
  getMe(): Promise<DetailResponse<UserResource>>
  editMe(info: UserFormValues): Promise<DetailResponse<UserResource>>
  updatePassword(info: PasswordFormValues): Promise<DetailResponse<UserResource>>
}

const baseUrl = 'auth'

export const UserService: UserServiceType = {
  async getMe() {
    const data = await API<DetailResponse<IUserResource>>({
      url: `${baseUrl}/me`,
      method: 'GET',
    })
    return {
      ...data,
      data: new UserResource(data.data),
    }
  },
  async editMe(info) {
    const user = {
      first_name: info.firstName,
      last_name: info.lastName,
      email: info.email,
    }
    const data = await API<DetailResponse<IUserResource>>({
      url: `${baseUrl}/me`,
      method: 'PATCH',
      data: user,
    })
    return {
      ...data,
      data: new UserResource(data.data),
    }
  },
  async updatePassword(info) {
    const value = {
      current_password: info.currentPassword,
      new_password: info.newPassword,
      repeat_new_password: info.repeatPassword,
    }
    const data = await API<DetailResponse<IUserResource>>({
      url: `${baseUrl}/change-password`,
      method: 'POST',
      data: value,
    })
    return {
      ...data,
      data: new UserResource(data.data),
    }
  },
}
