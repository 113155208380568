import { getMinCharsWarning } from 'src/helpers'
import * as Yup from 'yup'

export const formLabels = {
  nameText: 'Company name',
  emailText: 'Email',
  peechoKeyText: 'Peecho API key - only edit if you want to modify',
  peechoButtonKeyText: 'Peecho button key - only edit if you want to modify',
  successRedirectUrl: 'Success redirect URL - after payment is successful',
  cancellationRedirectUrl: 'Cancellation redirect URL - after payment gets cancelled',
  errorRedirectUrl: 'Error redirect URL - after payment has an error',
  address1Text: 'Address line 1',
  address2Text: 'Address line 2',
  cityText: 'City',
  zipCodeText: 'Zip code',
  stateText: 'State/Region',
  countryCodeText: 'Country code',
}

const {
  nameText,
  peechoKeyText,
  peechoButtonKeyText,
  cancellationRedirectUrl,
  errorRedirectUrl,
  successRedirectUrl,
  cityText,
  zipCodeText,
  stateText,
  countryCodeText,
} = formLabels

export const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, getMinCharsWarning(nameText, 2)),
  email: Yup.string().email('Invalid email'),
  addressLine1: Yup.string().min(2, getMinCharsWarning('Address', 2)),
  addressLine2: Yup.string().min(2, getMinCharsWarning('Address', 2)),
  peechoApiKey: Yup.string().min(5, getMinCharsWarning(peechoKeyText, 5)).optional(),
  peechoButtonKey: Yup.string().min(5, getMinCharsWarning(peechoButtonKeyText, 5)).optional(),
  cancellationRedirectUrl: Yup.string().min(5, getMinCharsWarning(cancellationRedirectUrl, 5)).optional().nullable(),
  errorRedirectUrl: Yup.string().min(5, getMinCharsWarning(errorRedirectUrl, 5)).optional().nullable(),
  successRedirectUrl: Yup.string().min(5, getMinCharsWarning(successRedirectUrl, 5)).optional().nullable(),
  city: Yup.string().min(2, getMinCharsWarning(cityText, 2)),
  zipCode: Yup.string().min(2, getMinCharsWarning(zipCodeText, 2)),
  state: Yup.string().min(2, getMinCharsWarning(stateText, 2)),
  countryCode: Yup.string().min(2, getMinCharsWarning(countryCodeText, 2)),
})
