import { FC } from 'react'
import { useAuthState, useMerchantState } from 'src/contexts'
import { MerchantResource, UserResource } from 'src/resources'
import { MerchantModalForm, TeamModalForm, UserModalForm } from './components'

interface AccountFormProps {
  user?: UserResource
  merchant?: MerchantResource
  merchantId?: number
  team?: UserResource[]
  toggleModal: () => void
}

const AccountForm: FC<AccountFormProps> = ({ toggleModal, user, merchant, team, merchantId }) => {
  const { editMerchant, addUser, deleteUser, updateUserRole } = useMerchantState()
  const { editMe } = useAuthState()
  const onToggle = () => {
    toggleModal()
  }

  return (
    <>
      {user && <UserModalForm user={user} toggleModal={onToggle} editMe={editMe} />}
      {merchant && (
        <MerchantModalForm merchant={merchant} toggleModal={onToggle} editMerchant={editMerchant} />
      )}
      {team && (
        <TeamModalForm
          team={team}
          toggleModal={onToggle}
          onAdd={addUser}
          onEdit={updateUserRole}
          merchantId={merchantId}
          onDelete={deleteUser}
        />
      )}
    </>
  )
}

export default AccountForm
