import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, Input, Modal } from 'src/components'
import { useAuthState } from 'src/contexts'
import { camelize } from 'src/helpers'
import { sleep } from 'src/helpers/sleep'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { greenColor, warningColor } from 'src/styles'
import { PasswordFormValues } from 'src/types'
import * as Yup from 'yup'

interface PasswordModalFormProps {
  toggleModal: () => void
}

const PasswordModalForm: FC<PasswordModalFormProps> = ({ toggleModal }) => {
  const { updatePassword } = useAuthState()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()
  const [isSubmitted, setIsSubmitted] = useState(false)

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  }

  const requiredFieldText = 'This field is required.'
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(2, 'First name must be 2 characters or longer')
      .required(requiredFieldText),
    newPassword: Yup.string()
      .min(8, 'Password must be 8 characters or longer')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Password must contain an uppercase, a lowercase, a number and one special character',
      )
      .required(requiredFieldText),
    repeatPassword: Yup.string()
      .required(requiredFieldText)
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  })

  const onToggle = () => {
    toggleModal()
    setIsSubmitted(false)
    setIsSubmitting(false)
    setErrorMessage(undefined)
  }

  const onSubmit = async (values: PasswordFormValues) => {
    if (!updatePassword) return

    setIsSubmitted(false)
    setIsSubmitting(true)
    try {
      await sleep(300)
      await updatePassword(values)
      setIsSubmitted(true)
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const title = 'Change password'
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleChange, handleBlur, values, isValid }) => {
        const fields = [
          { label: 'Current password', value: values.currentPassword },
          { label: 'New password', value: values.newPassword },
          { label: 'Repeat Password', value: values.repeatPassword },
        ]

        return (
          <Modal toggleModal={onToggle} title={title}>
            <Form>
              {fields.map(({ label, value }) => (
                <div key={label}>
                  <Input
                    label={label}
                    type="password"
                    name={camelize(label)}
                    id={camelize(label)}
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={label}
                  />
                </div>
              ))}
              {errorMessage && (
                <p style={{ color: warningColor, textAlign: 'end' }}>{errorMessage}</p>
              )}
              {isSubmitted && (
                <p style={{ color: greenColor, fontWeight: 'bold' }}>
                  Password successfully updated
                </p>
              )}
              <div className="buttons">
                <Button text="Cancel" onClick={onToggle} theme="light" />
                <Button type="submit" disabled={!isValid} text="Submit" isLoading={isSubmitting} />
              </div>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default PasswordModalForm
