import { FC } from 'react'
import { ImagePagePlaceholder, TextPagePlaceholder } from 'src/components'

interface CoverOptionProps {
  type: number
  color: string
}

const CoverOption: FC<CoverOptionProps> = ({ type, color }) => {
  let placeholder = <div />

  switch (type) {
    case 1:
      placeholder = <ImagePagePlaceholder color={color} type="landscape" />
      break
    case 2:
      placeholder = <ImagePagePlaceholder color={color} type="columns" />
      break
    case 3:
      placeholder = <TextPagePlaceholder color={color} type="columnsAndTitle" />
      break
    case 4:
      placeholder = <TextPagePlaceholder color={color} type="title" />
      break
    case 46:
      placeholder = <ImagePagePlaceholder color={color} type="landscape" hasTitle={true} />
      break
    case 47:
      placeholder = <ImagePagePlaceholder color={color} type="column" hasTitle={true} />
      break
  }

  return <>{placeholder}</>
}

export default CoverOption
