import { FC } from 'react'
import { UserResource } from 'src/resources'
import { TeamRow } from '../../TeamRow'
import '../AccountCard.scss'
import { AccountCardWrapper } from '../components'

interface TeamCardProps {
  team: UserResource[] | undefined
  toggleModal: () => void
}

const TeamCard: FC<TeamCardProps> = ({ toggleModal, team }) => (
  <AccountCardWrapper section="team" toggleModal={toggleModal} isAdmin>
    {team?.map((user) => (
      <TeamRow key={user.id} user={user} />
    ))}
  </AccountCardWrapper>
)

export default TeamCard
