import { Form, Formik } from 'formik'
import { CSSProperties, Dispatch, FC, SetStateAction } from 'react'
import { CheckBox } from 'src/components'
import { useTemplateState } from 'src/contexts'
import { isErrorResource, LayoutResource } from 'src/resources'
import { primaryColor, templateColor } from 'src/styles'

interface PageLayoutSelectProps {
  options: LayoutResource[]
  setError: Dispatch<SetStateAction<string | undefined>>
}

const PageLayoutSelect: FC<PageLayoutSelectProps> = ({ options, setError }) => {
  const { togglePageLayout } = useTemplateState()

  const layoutSelectStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '5px 20px 5px 0',
  } as CSSProperties
  const inputStyle = {
    marginTop: 11,
  }

  const generateInitialValues = () => {
    const arrayOfValues = options?.map(({ id, is_enabled }) => {
      const initialVals = {
        key: id,
        value: is_enabled,
      }
      return initialVals
    })

    const convertedValues = {}
    for (let i = 0; i < arrayOfValues.length; i++) {
      convertedValues[arrayOfValues[i].key] = arrayOfValues[i].value
    }
    return convertedValues
  }

  const initialValues = generateInitialValues()

  const onSubmit = () => {
    return
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form
          style={{ display: 'flex', flexWrap: 'wrap' }}
          onSubmit={() => {
            handleSubmit()
          }}>
          {options.map(({ id, title, is_protected, is_enabled, thumbnail_url }) => {
            const handleChange = async () => {
              setError(undefined)
              if (!togglePageLayout) return
              try {
                await togglePageLayout(id, !is_enabled)
              } catch (e) {
                if (isErrorResource(e)) {
                  setError(e.data.error.message)
                }
              }
            }
            const color = is_enabled || is_protected ? primaryColor : templateColor
            const name = `${id}`
            const url = thumbnail_url || ''

            return (
              <div style={layoutSelectStyle} key={id} onClick={handleChange}>
                <img
                  src={url}
                  alt={title}
                  height={50}
                  style={{ border: `1px solid ${color}`, borderRadius: 3 }}
                />
                {!is_protected && (
                  <div style={inputStyle}>
                    <CheckBox name={name} id={name} value={is_enabled} />
                  </div>
                )}
              </div>
            )
          })}
        </Form>
      )}
    </Formik>
  )
}

export default PageLayoutSelect
