import { FC } from 'react'
import { SuccessAlert } from 'src/components'
import './ApiKeyModal.scss'

interface ApiKeyModalProps {
  apiKey?: string
  onClose: () => void
}

const ApiKeyModal: FC<ApiKeyModalProps> = ({ onClose, apiKey }) => {
  const message = 'Your api-key has been successfully generated'
  return (
    <SuccessAlert onClose={onClose} message={message} noDisappear>
      <div style={{ padding: 5, lineHeight: 1.5 }}>
        This api key is unique to your organisation and will be needed to create orders.
        <br />
        Remember to store it in a safe place.
        <br />
        <div style={{ marginTop: 20, marginBottom: 10, fontSize: 15 }}>Api-key:</div>
        <div className="key">{apiKey}</div>
      </div>
    </SuccessAlert>
  )
}

export default ApiKeyModal
