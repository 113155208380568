import { CSSProperties, FC } from 'react'
import DefaultInput from '../DefaultInput'
import './Slider.scss'

export interface SliderProps {
  max: number
  min: number
  label: string
  value: number
  id: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Slider: FC<SliderProps> = ({ value, id, label, max, min, onChange }) => {
  const textStyle = {
    marginRight: 5,
    fontSize: 13,
    fontWeight: 'bold',
  } as CSSProperties
  return (
    <div className="sliderContainer">
      <div className="sliderLabel" style={textStyle}>
        {label}{' '}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DefaultInput
          className="slider"
          type="range"
          min={min}
          max={max}
          value={value}
          name={id}
          id={id}
          onChange={onChange}
        />
        <span className="rangeValue">{`${value}px`}</span>
      </div>
    </div>
  )
}

export default Slider
