import API from 'src/api'
import {ILayoutResource, ITemplateResource, LayoutResource} from 'src/resources'
import {DetailResponse} from 'src/types'

export type TemplateServiceType = {
  getTemplateLayouts(): Promise<DetailResponse<LayoutResource[]>>
  togglePageLayout(layoutId: number, isEnabled: boolean): Promise<DetailResponse<LayoutResource[]>>
  getBookViewerSample(): Promise<string>
}

const baseUrl = 'merchants/me/template'

export const TemplateService: TemplateServiceType = {
  async getTemplateLayouts() {
    const data = await API<DetailResponse<ILayoutResource[]>>({
      url: `${baseUrl}/layouts`,
      method: 'GET',
    })
    return {
      ...data,
      data: data.data.map((i) => new LayoutResource(i)),
    }
  },

  async togglePageLayout(layoutId, isEnabled) {
    const data = await API<DetailResponse<ITemplateResource>>({
      url: `${baseUrl}/layouts/${layoutId}/${isEnabled ? 'en' : 'dis'}able`,
      method: 'POST',
    })
    return {
      ...data,
      data: data.data.layouts.map((i) => new LayoutResource(i)),
    }
  },


  async getBookViewerSample() {
    return await API<string>({
      url: `${baseUrl}/sample/viewer`,
      method: 'GET',
    })
  },
}
