import API from 'src/api'
import { IMerchantResource, MerchantResource } from 'src/resources'
import { ITemplateResource, TemplateResource } from 'src/resources/template/TemplatesResource'
import { DetailResponse, EditMerchantValues, EditTemplateValues, UserFormValues } from 'src/types'

export type MerchantServiceType = {
  getMerchant(): Promise<DetailResponse<MerchantResource>>
  editMerchant(id: number, info: EditMerchantValues): Promise<DetailResponse<MerchantResource>>
  editLogo(id: number, info, isCover?: boolean): Promise<DetailResponse<MerchantResource>>
  editTemplate(info: EditTemplateValues): Promise<DetailResponse<TemplateResource>>
  addUser(values: UserFormValues): Promise<DetailResponse<MerchantResource>>
  deleteUser(id: number): Promise<DetailResponse<MerchantResource>>
  updateUserRole(
    id: number,
    userId: number,
    role: string,
  ): Promise<DetailResponse<MerchantResource>>
  generateApiKey(id: number, role?: string): Promise<DetailResponse<{ api_key: string }, undefined>>
}

const baseUrl = 'merchants'

export const MerchantService: MerchantServiceType = {
  async getMerchant() {
    const data = await API<DetailResponse<IMerchantResource>>({
      url: `${baseUrl}/me`,
      method: 'GET',
    })
    return {
      ...data,
      data: new MerchantResource(data.data),
    }
  },

  async editMerchant(id, info) {
    const merchant = {
      name: info.name,
      cancellation_redirect_url: info.cancellationRedirectUrl,
      error_redirect_url: info.errorRedirectUrl,
      success_redirect_url: info.successRedirectUrl,
      billing_email_address: info.email,
      'billing_address[address_line_1]': info.addressLine1,
      'billing_address[address_line_2]': info.addressLine2,
      'billing_address[zip_code]': info.zipCode,
      'billing_address[city]': info.city,
      'billing_address[state]': info.state,
      'billing_address[country_code]': info.countryCode,
    }

    const formData = new FormData()
    for (const key in merchant) {
      formData.append(key, merchant[key])
    }

    const data = await API<DetailResponse<IMerchantResource>>({
      url: `${baseUrl}/${id}`,
      method: 'PATCH',
      data: formData,
    })
    return {
      ...data,
      data: new MerchantResource(data.data),
    }
  },

  async editLogo(id, info, isCover) {
    const merchant = isCover
      ? {
          logo: info.logo,
        }
      : {
          spine_logo: info.logo,
        }
    const formData = new FormData()
    for (const key in merchant) {
      formData.append(key, merchant[key])
    }

    const data = await API<DetailResponse<IMerchantResource>>({
      url: `${baseUrl}/${id}`,
      method: 'PATCH',
      data: formData,
    })
    return {
      ...data,
      data: new MerchantResource(data.data),
    }
  },

  async editTemplate(info) {
    const data = await API<DetailResponse<ITemplateResource>>({
      url: `${baseUrl}/me/template`,
      method: 'PATCH',
      data: info,
    })
    return {
      ...data,
      data: new TemplateResource(data.data),
    }
  },

  async addUser(values) {
    const user = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      role: values.role,
    }
    const data = await API<DetailResponse<IMerchantResource>>({
      url: `${baseUrl}/me/users/add`,
      method: 'POST',
      data: user,
    })
    return {
      ...data,
      data: new MerchantResource(data.data),
    }
  },

  async deleteUser(id) {
    const data = await API<DetailResponse<IMerchantResource>>({
      url: `${baseUrl}/me/users/${id}`,
      method: 'DELETE',
    })
    return {
      ...data,
      data: new MerchantResource(data.data),
    }
  },

  async updateUserRole(id, userId, role) {
    const data = await API<DetailResponse<IMerchantResource>>({
      url: `${baseUrl}/${id}/users/${userId}/role`,
      method: 'PATCH',
      data: role,
    })
    return {
      ...data,
      data: new MerchantResource(data.data),
    }
  },

  async generateApiKey(id, role) {
    const data = await API<DetailResponse<{ api_key: string }, undefined>>({
      url: `${baseUrl}/${id}/${role}/generate-api-key`,
      method: 'POST',
    })
    return {
      ...data,
      data: data.data,
    }
  },
}
