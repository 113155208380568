import { FC } from 'react'
import { IconsProps } from './Icon'

const EditIcon: FC<IconsProps> = ({ color }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.78603 2.83768C6.73879 2.79044 6.6632 2.79044 6.61595 2.83768L0.639589 8.81405C0.618329 8.85657 0.597069 8.89436 0.573447 8.93452L0.00415666 11.7172C-0.0265519 11.8825 0.117542 12.0266 0.282896 11.9959L3.06321 11.4266C3.10573 11.4054 3.14352 11.3841 3.18368 11.3605L9.16241 5.38177C9.20965 5.33452 9.20965 5.25893 9.16241 5.21169L6.78603 2.83768Z"
      fill={color}
    />
    <path
      d="M11.8246 1.87382L10.1262 0.175393C9.89232 -0.0584645 9.512 -0.0584645 9.27814 0.175393L7.63642 1.81948C7.58917 1.86673 7.58917 1.94232 7.63642 1.98956L10.0128 4.36594C10.06 4.41318 10.1356 4.41318 10.1829 4.36594L11.8246 2.72421C12.0585 2.48799 12.0585 2.10767 11.8246 1.87382Z"
      fill={color}
    />
  </svg>
)

export default EditIcon
