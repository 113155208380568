import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, Input, Modal } from 'src/components'
import { useAuthState } from 'src/contexts'
import { camelize, sleep } from 'src/helpers'
import { isErrorResource, MerchantResource, UserResource } from 'src/resources'
import { UserFormValues } from 'src/types'
import { TeamRow } from '../../../TeamRow'
import { UserModalForm } from '../UserModalForm'

interface TeamModalFormProps {
  team: UserResource[]
  error?: string
  merchantId?: number
  onAdd?: ((values: UserFormValues) => Promise<void | MerchantResource>) | undefined
  onDelete: ((id: number) => Promise<void | MerchantResource>) | undefined
  toggleModal: () => void
  onEdit?: (id: number, userId: number, role: string) => Promise<MerchantResource | undefined>
}

const TeamModalForm: FC<TeamModalFormProps> = ({
  error,
  team,
  onAdd,
  onDelete,
  toggleModal,
  onEdit,
  merchantId,
}) => {
  const { user: me } = useAuthState()
  const isAdmin = !!me && me.role === 'admin'

  const [isEditModal, setIsEditModal] = useState(false)
  const [isAddMember, setIsAddMember] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedUser, setSelectedUser] = useState<undefined | UserResource>()
  const [successMessage, setSuccessMessage] = useState<undefined | string>()
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const handleAddMember = () => setIsAddMember(true)

  const isTeamModal = !isAddMember && selectedUser === undefined

  const deleteUser = (user: UserResource) => {
    if (!onDelete) return
    onDelete(user.id)
  }

  const editUser = (user: UserResource) => {
    setSelectedUser(user)
    setIsEditModal(true)
  }

  const onSubmitEdit = async (values) => {
    setErrorMessage(undefined)
    setIsSubmitting(true)
    try {
      await sleep(300)
      if (onEdit && selectedUser && merchantId) {
        await onEdit(merchantId, selectedUser.id, values)
      }
      toggleModal()
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const initialValues = {
    role: selectedUser?.role ?? 'user',
  }
  const roleText = 'Role'

  return (
    <>
      {isTeamModal && (
        <Modal
          toggleModal={toggleModal}
          title="Team members"
          error={errorMessage || error}
          successMessage={successMessage}>
          {isAdmin && (
            <Button text="Add new" onClick={handleAddMember} theme="light" icon="addUser" bold />
          )}
          {team.map((member) => (
            <TeamRow
              key={member.id}
              user={member}
              onDelete={deleteUser}
              onEdit={editUser}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
            />
          ))}
        </Modal>
      )}
      {isAddMember && <UserModalForm toggleModal={toggleModal} addUser={onAdd} />}
      {isEditModal && (
        <Formik initialValues={initialValues} onSubmit={onSubmitEdit}>
          {({ handleChange, handleBlur, values, isValid }) => {
            const roleOptions = [
              { value: 'user', label: 'User' },
              { value: 'admin', label: 'Admin' },
            ]

            return (
              <Modal
                toggleModal={toggleModal}
                title={`Edit ${selectedUser?.fullName} role`}
                error={errorMessage || error}>
                <Form>
                  <Input
                    label={roleText}
                    type="text"
                    name={camelize(roleText)}
                    id={camelize(roleText)}
                    value={values.role}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={roleText}
                    options={roleOptions}
                  />

                  <div className="buttons">
                    <Button text="Cancel" onClick={toggleModal} theme="light" />
                    <Button
                      type="submit"
                      disabled={!isValid}
                      isLoading={isSubmitting}
                      text="Submit"
                    />
                  </div>
                </Form>
              </Modal>
            )
          }}
        </Formik>
      )}
    </>
  )
}

export default TeamModalForm
