import { IOrderResource, OrderResource } from '../orders/OrdersResource'
import { ITemplateResource, TemplateResource } from '../template/TemplatesResource'
import { IUserResource, UserResource } from '../user/UserResource'

export interface IBillingAddressResource {
  address_line_1: string
  address_line_2?: string
  city: string
  country_code: string
  created_at: Date
  id: number
  state: string
  zip_code: string
}

export class BillingAddressResource {
  public readonly id: number

  public addressLine1: string

  public addressLine2: string

  public zipCode: string

  public city: string

  public state: string

  public countryCode: string

  constructor(billingAddress: IBillingAddressResource) {
    this.id = billingAddress.id
    this.addressLine1 = billingAddress.address_line_1
    this.addressLine2 = billingAddress.address_line_2 ?? ""
    this.zipCode = billingAddress.zip_code
    this.city = billingAddress.city
    this.state = billingAddress.state
    this.countryCode = billingAddress.country_code
  }
}

export interface IMerchantResource {
  id: number
  name: string
  billing_address: IBillingAddressResource
  billing_email_address: string
  is_verified: boolean
  api_key: string
  has_api_key: boolean
  orders?: IOrderResource[]
  template: ITemplateResource
  users: IUserResource[]
  spine_logo_url?: string
  logo_url: string
  peecho_merchant_api_key: string
  peecho_button_key: string
  cancellation_redirect_url: string | null
  error_redirect_url: string | null
  success_redirect_url: string | null
  created_at?: Date
  updated_at?: Date
}

export class MerchantResource {
  public readonly id: number

  public addressLine1: string

  public addressLine2: string

  public zipCode: string

  public city: string

  public state: string

  public countryCode: string

  public email: string

  public isVerified: boolean

  public name: string

  public logoUrl?: string

  public spineLogoUrl?: string

  public hasApiKey: boolean

  public apiKey?: string

  public peechoApiKey?: string

  public peechoButtonKey?: string
  public cancellationRedirectUrl: string | null
  public errorRedirectUrl: string | null
  public successRedirectUrl: string | null

  public template: TemplateResource

  public orders?: OrderResource[] | []

  public users?: UserResource[]

  public createdAt: Date

  public updatedAt?: Date

  constructor(merchant: IMerchantResource) {
    this.id = merchant.id
    this.name = merchant.name
    this.addressLine1 = merchant.billing_address.address_line_1
    this.addressLine2 = merchant.billing_address.address_line_2 ?? ""
    this.zipCode = merchant.billing_address.zip_code
    this.city = merchant.billing_address.city
    this.state = merchant.billing_address.state
    this.countryCode = merchant.billing_address.country_code
    this.email = merchant.billing_email_address
    this.isVerified = merchant.is_verified
    this.apiKey = merchant.api_key
    this.peechoApiKey = merchant.peecho_merchant_api_key
    this.peechoButtonKey = merchant.peecho_button_key
    this.cancellationRedirectUrl = merchant.cancellation_redirect_url
    this.errorRedirectUrl = merchant.error_redirect_url
    this.successRedirectUrl = merchant.success_redirect_url
    this.hasApiKey = merchant.has_api_key
    this.spineLogoUrl = merchant.spine_logo_url
    this.logoUrl = merchant.logo_url
    this.template = new TemplateResource(merchant.template)
    this.orders = merchant.orders ? merchant.orders.map((i) => new OrderResource(i)) : []
    this.users = merchant.users ? merchant.users.map((i) => new UserResource(i)) : []
    this.createdAt = merchant.created_at ? new Date(merchant.created_at) : new Date()
    this.updatedAt = merchant.updated_at ? new Date(merchant.updated_at) : new Date()
  }
}
