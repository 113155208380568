import FontPicker from 'font-picker-react'
import { FC, useState } from 'react'
import { useFontsState } from 'src/contexts'
import { borderColor } from 'src/styles'
import { BrandingWrapper } from '../BrandingWrapper'
import { FontButton } from './components'

interface FontCardProps {
  currentFontId: number
}

const FontCard: FC<FontCardProps> = ({ currentFontId }) => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_FONTS_API_KEY ?? ''
  const { fonts } = useFontsState()
  const [cardError, setCardError] = useState<string | undefined>('')

  const title = 'Typography'
  const instructions = 'Select the font for your photobooks'
  return (
    <BrandingWrapper title={title} className="fonts-wrapper" error={cardError} instructions={instructions}>
      {/* FontPicker allows to display the fonts without having to download them */}
      <div style={{ display: 'none' }}>
        <FontPicker
          apiKey={googleApiKey}
          activeFontFamily={'Open Sans'}
          onChange={() => {
            return
          }}
        />
      </div>
      <div style={{ border: `1px solid ${borderColor}`, borderRadius: 15 }}>
        {fonts?.map((font) => (
          <FontButton
            currentFontId={currentFontId}
            key={font.id}
            fontId={font.id}
            fontFamily={font.family}
            setCardError={setCardError}
          />
        ))}
      </div>
    </BrandingWrapper>
  )
}

export default FontCard
