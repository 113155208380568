import { FC } from 'react'
import { IconsProps } from './Icon'

const ArrowIcon: FC<IconsProps> = ({ color, deg }) => {
  const transform = `rotate(${deg})`
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" transform={transform}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.0517 0.169687L4.99435 4.19947L8.95878 0.169687C9.48156 -0.374878 10.3746 0.518208 9.83008 1.06277L5.36465 5.6589C5.16861 5.85494 4.84187 5.85494 4.66761 5.6589L0.158609 1.06277C-0.364173 0.518208 0.528914 -0.374878 1.0517 0.169687Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowIcon
