import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, CheckBox, Input, logoOptions, Slider, filesOrderOptions } from 'src/components'
import { useMerchantState } from 'src/contexts'
import { sleep } from 'src/helpers/sleep'
import { TemplateResource } from 'src/resources'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { EditTemplateValues } from 'src/types'
import { BrandingWrapper } from '../BrandingWrapper'
import './LayoutCard.scss'

interface LayoutCardProps {
  merchantTemplate: TemplateResource
}

const LayoutCard: FC<LayoutCardProps> = ({ merchantTemplate }) => {
  const { editTemplate } = useMerchantState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const initialValues = {
    has_cover_logo: merchantTemplate.has_cover_logo || false,
    has_cover_title: merchantTemplate.has_cover_title || false,
    has_spine_logo: merchantTemplate.has_spine_logo || false,
    has_spine_title: merchantTemplate.has_spine_title || false,
    logo_position: merchantTemplate.logo_position || 'center',
    logo_size: `${merchantTemplate.logo_size || '40px'}`,
    files_order: merchantTemplate.files_order
  }

  const onSubmit = async (values: EditTemplateValues) => {
    if (!editTemplate) return

    const layoutValues = {
      has_cover_logo: values.has_cover_logo,
      has_cover_title: values.has_cover_title,
      has_spine_logo: values.has_spine_logo,
      has_spine_title: values.has_spine_title,
      logo_position: values.logo_position,
      logo_size: `${values.logo_size}px`,
      files_order: values.files_order
    }

    setErrorMessage(undefined)
    setIsSubmitting(true)
    try {
      await sleep(500)
      await editTemplate(layoutValues)
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const instructions = 'Select your options and apply'

  return (
    <BrandingWrapper
      title="Customize layout"
      error={errorMessage}
      className="layout-wrapper"
      instructions={instructions}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, isValid, handleChange, handleSubmit }) => (
          <Form
            className="layoutWrapper"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}>
            <div className="LayoutForm">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                <div role="group" className="sectionInputs">
                  <legend className="sectionInputTitle">Cover</legend>
                  <CheckBox
                    label="Display title"
                    name="has_cover_title"
                    id="has_cover_title"
                    value={values.has_cover_title}
                    onChange={handleChange}
                  />
                  <CheckBox
                    label="Display logo"
                    name="has_cover_logo"
                    id="has_cover_logo"
                    value={values.has_cover_logo}
                    onChange={handleChange}
                  />
                </div>
                <div className="sectionInputs">
                  <legend className="sectionInputTitle">Spine</legend>
                  {/* Uncomment once SPINE_LOGO is implemented in BE */}
                  {/* <CheckBox
                    label="Display logo"
                    name="has_spine_logo"
                    id="has_spine_logo"
                    value={values.has_spine_logo}
                    onChange={handleChange}
                  /> */}
                  <CheckBox
                    label="Display title"
                    name="has_spine_title"
                    id="has_spine_title"
                    value={values.has_spine_title}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <Input
                label="Logo position"
                type="text"
                name="logo_position"
                id="logo_position"
                value={values.logo_position}
                onChange={handleChange}
                placeholder="Logo position"
                options={logoOptions}
              />
              <Input
                label="Photo order"
                type="text"
                name="files_order"
                id="files_order"
                value={values.files_order}
                onChange={handleChange}
                placeholder="Select a photo order"
                options={filesOrderOptions}
              />
              <Slider
                label={'Logo height'}
                min={10}
                max={800}
                value={parseInt(values.logo_size, 10)}
                id="logo_size"
                onChange={handleChange}
              />
            </div>
            <Button
              type="submit"
              disabled={!isValid}
              text="Apply"
              isLoading={isSubmitting}
              mb={1}
              mt={1}
            />
          </Form>
        )}
      </Formik>
    </BrandingWrapper>
  )
}

export default LayoutCard
