import { FC, useMemo, useRef, useState } from 'react'
import { LoadingAnimation, Wrapper } from 'src/components'
import { useTemplateState } from 'src/contexts'
import { sleep } from 'src/helpers'
import './BookPreview.scss'

const BookPreview: FC = () => {
  const { templatePreview: preview, getBookViewerSample } = useTemplateState()
  const { current: templatePreview } = useRef(getBookViewerSample)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useMemo(() => {
    if (!templatePreview) return
    ;(async () => {
      setIsLoading(true)
      try {
        await templatePreview()
        await sleep(1500)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [templatePreview])

  return (
    <Wrapper>
      <div className="PreviewWrapper">
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <iframe
              height="100%"
              width="100%"
              style={{ borderRadius: 10 }}
              title="Book sample"
              srcDoc={preview}
              frameBorder="0"
            />
          </>
        )}
      </div>
    </Wrapper>
  )
}

export default BookPreview
