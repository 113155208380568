import { FC } from 'react'
import { AuthWrapper, UnauthWrapper } from './InnerWrappers'
import './Wrapper.scss'

interface WrapperProps {
  hasError?: boolean
  title?: string
  entry?: string
  error?: string
}

const Wrapper: FC<WrapperProps> = ({ title, entry, error, hasError, children }) => (
  <div className="wrapper">
    {/* Only unauthenticated routes require title and entry */}
    {title && entry ? (
      <UnauthWrapper title={title} entry={entry} hasError={hasError} error={error}>
        {children}
      </UnauthWrapper>
    ) : (
      <AuthWrapper>{children}</AuthWrapper>
    )}
  </div>
)

export default Wrapper
