import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, Input, Modal } from 'src/components'
import { camelize, sleep } from 'src/helpers'
import { isErrorResource, MerchantResource, UserResource } from 'src/resources'
import { UserFormValues } from 'src/types'
import * as Yup from 'yup'
import '../AccountForms.scss'

interface UserModalFormProps {
  user?: UserResource
  addUser?: ((info: UserFormValues) => Promise<void | MerchantResource>) | undefined
  editMe?: ((info: UserFormValues) => Promise<void | UserResource>) | undefined
  toggleModal: () => void
}

const firstNameText = 'First name'
const lastNameText = 'Last name'
const emailText = 'Email'
const roleText = 'Role'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'First name must be 2 characters or longer'),
  lastName: Yup.string().min(2, 'Last name must be 2 characters or longer'),
  email: Yup.string().email('Invalid email'),
  role: Yup.string(),
})

const UserModalForm: FC<UserModalFormProps> = ({ toggleModal, user, editMe, addUser }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const initialValues = {
    firstName: addUser ? '' : user?.firstName ?? '',
    lastName: addUser ? '' : user?.lastName ?? '',
    email: addUser ? '' : user?.email ?? '',
    role: addUser ? '' : user?.role ?? '',
  }

  const onSubmit = async (values: UserFormValues) => {
    setErrorMessage(undefined)
    setIsSubmitting(true)

    try {
      await sleep(300)
      if (editMe) {
        await editMe(values)
      }
      if (addUser) {
        await addUser(values)
      }
      toggleModal()
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }
  const getTitle = (): string => {
    if (addUser) {
      return 'Add new'
    }
    if (user) {
      return 'Edit'
    } else {
      return 'Profile information'
    }
  }
  const title = getTitle()

  const buttonText = addUser ? 'Add member' : 'Save changes'
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleChange, handleBlur, values, isValid }) => {
        const roleOptions = [
          { value: 'user', label: 'User' },
          { value: 'admin', label: 'Admin' },
        ]
        // user cannot change his own role
        const fields = !editMe
          ? [
              { label: firstNameText, value: values.firstName },
              { label: lastNameText, value: values.lastName },
              { label: emailText, value: values.email },
              { label: roleText, value: values.role, options: roleOptions },
            ]
          : [
              { label: firstNameText, value: values.firstName },
              { label: lastNameText, value: values.lastName },
              { label: emailText, value: values.email },
            ]

        return (
          <Modal toggleModal={toggleModal} title={title} error={errorMessage}>
            <Form>
              {fields.map(({ label, value, options }) => (
                <div key={label}>
                  <Input
                    label={label}
                    type="text"
                    name={camelize(label)}
                    id={camelize(label)}
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={label}
                    options={options}
                  />
                </div>
              ))}
              <div className="buttons">
                <Button text="Cancel" onClick={toggleModal} theme="light" />
                <Button
                  type="submit"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  text={buttonText}
                />
              </div>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default UserModalForm
