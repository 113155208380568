import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, countryOptions, Input, Modal } from 'src/components'
import { sleep } from 'src/helpers/sleep'
import { MerchantResource } from 'src/resources'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { EditMerchantValues } from 'src/types'
import '../AccountForms.scss'
import { formLabels, validationSchema } from './formInfo'

interface MerchantModalFormProps {
  merchant: MerchantResource
  editMerchant:
    | ((id: number, info: EditMerchantValues) => Promise<void | MerchantResource>)
    | undefined
  toggleModal: () => void
}

const MerchantModalForm: FC<MerchantModalFormProps> = ({ merchant, editMerchant, toggleModal }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const {
    nameText,
    emailText,
    cancellationRedirectUrl,
    errorRedirectUrl,
    successRedirectUrl,
    address1Text,
    address2Text,
    cityText,
    zipCodeText,
    stateText,
    countryCodeText,
  } = formLabels

  const initialValues = {
    name: merchant.name,
    email: merchant.email,
    addressLine1: merchant.addressLine1,
    addressLine2: merchant.addressLine2,
    city: merchant.city,
    zipCode: merchant.zipCode,
    state: merchant.state,
    countryCode: merchant.countryCode,
    cancellationRedirectUrl: merchant.cancellationRedirectUrl,
    errorRedirectUrl: merchant.errorRedirectUrl,
    successRedirectUrl: merchant.successRedirectUrl,
  }

  const onSubmit = async (values) => {
    if (!editMerchant) return
    setErrorMessage(undefined)
    setIsSubmitting(true)
    try {
      await sleep(300)
      await editMerchant(merchant.id, values)
      toggleModal()
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleChange, handleBlur, values, isValid }) => {
        const fields = [
          { name: 'nameText', label: nameText, value: values.name },
          { name: 'emailText', label: emailText, value: values.email },
          { name: 'address1Text', label: address1Text, value: values.addressLine1 },
          { name: 'address2Text', label: address2Text, value: values.addressLine2 },
          { name: 'cityText', label: cityText, value: values.city },
          { name: 'zipCodeText', label: zipCodeText, value: values.zipCode },
          { name: 'stateText', label: stateText, value: values.state },
          { name: 'countryCodeText', label: countryCodeText, value: values.countryCode, options: countryOptions },
          { name: 'successRedirectUrl', label: successRedirectUrl, value: values.successRedirectUrl },
          { name: 'cancellationRedirectUrl', label: cancellationRedirectUrl, value: values.cancellationRedirectUrl },
          { name: 'errorRedirectUrl', label: errorRedirectUrl, value: values.errorRedirectUrl },
        ]
        return (
          <Modal toggleModal={toggleModal} title="Company information" error={errorMessage}>
            <Form>
              {fields.map(({ name, label, value, options }) => {
                const getCamelized = () => {
                  if (name === 'stateText') {
                    return 'state'
                  }
                  if (name === 'nameText') {
                    return 'name'
                  } else {
                    return name
                  }
                }
                const camelizedVal = getCamelized()
                return (
                  <div key={label}>
                    <Input
                      label={label}
                      type="text"
                      name={camelizedVal}
                      id={camelizedVal}
                      value={value}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={label}
                      options={options}
                    />
                  </div>
                )
              })}
              <div className="buttons">
                <Button text="Cancel" onClick={toggleModal} theme="light" />
                <Button
                  type="submit"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  text="Save changes"
                />
              </div>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default MerchantModalForm
