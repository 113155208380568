import { Field } from 'formik'
import { FC } from 'react'
import { backgroundColor, primaryColor } from 'src/styles'
import './RadioInput.scss'

export interface RadioInputProps {
  name: string
  value: boolean
  id: number | string
  onChange?: () => void
}

export const RadioInput: FC<RadioInputProps> = ({ name, value, id, onChange }) => {
  const radioBackground = value ? primaryColor : backgroundColor
  return (
    <div className="radioWrapper">
      <Field type="radio" name={name} value={value} id={id} checked={value} onChange={onChange} />
      <span className="styledRadio" style={{ backgroundColor: radioBackground }}></span>
    </div>
  )
}

export default RadioInput
