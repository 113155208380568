import { Form, Formik } from 'formik'
import { CSSProperties, Dispatch, FC, SetStateAction } from 'react'
import { RadioInput } from 'src/components'
import { useMerchantState } from 'src/contexts'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { primaryColor, templateColor } from 'src/styles'
import CoverOption from './CoverOption'

interface CoverLayoutSelectProps {
  options: number[]
  coverLayoutId: number
  setCoverLayout: Dispatch<SetStateAction<number>>
  setCardError: Dispatch<SetStateAction<string | undefined>>
}

const CoverLayoutSelect: FC<CoverLayoutSelectProps> = ({
  coverLayoutId,
  options,
  setCoverLayout,
  setCardError,
}) => {
  const { editTemplate } = useMerchantState()

  const layoutSelectStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as CSSProperties
  const inputStyle = {
    marginTop: 11,
  }

  const initialValues = {
    coverLayout: coverLayoutId,
  }

  const onSubmit = () => {
    return
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form
          style={{ display: 'flex', alignItems: 'center', gap: '20px', flexWrap: 'wrap' }}
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}>
          {options.map((option) => {
            const handleChange = async () => {
              setCoverLayout(option)
              setCardError(undefined)
              if (editTemplate) {
                const info = {
                  cover_layout_id: option,
                }
                try {
                  await editTemplate(info)
                } catch (e) {
                  if (isErrorResource(e)) {
                    setCardError(e.data.error.message)
                  }
                }
              }
            }
            const checked = coverLayoutId === option
            const color = checked ? primaryColor : templateColor
            return (
              <label htmlFor={`cover-${option}`} style={layoutSelectStyle} key={option}>
                <CoverOption color={color} type={option} />
                <div style={inputStyle}>
                  <RadioInput name="coverLayout" id={`cover-${option}`} value={checked} onChange={handleChange} />
                </div>
              </label>
            )
          })}
        </Form>
      )}
    </Formik>
  )
}

export default CoverLayoutSelect
