import API from 'src/api'
import { FontResource, IFontResource } from 'src/resources'
import { IndexResponse } from 'src/types'

export type FontsServiceType = {
  getFonts(): Promise<IndexResponse<FontResource>>
}

const baseUrl = '/fonts'

export const FontsService: FontsServiceType = {
  async getFonts() {
    const data = await API<IndexResponse<IFontResource>>({
      url: baseUrl,
      method: 'GET',
    })
    return {
      ...data,
      data: data.data.map((i) => new FontResource(i)),
    }
  },
}
