import { FC } from 'react'
import './InitialsBadge.scss'

export interface InitialsBadgeProps {
  initials: string
  color?: 'red'
}

export const InitialsBadge: FC<InitialsBadgeProps> = ({ initials, color }) => (
  <div className={`initialsBadge ${color}`}>{initials}</div>
)

export default InitialsBadge
