import { FC } from 'react'
import { IconsProps } from './Icon'

const UserIcon: FC<IconsProps> = ({ color }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8905 10.141C11.7473 9.54289 12.3984 8.69308 12.7524 7.70802C13.1191 6.6877 13.1477 5.57645 12.8339 4.53862C12.5202 3.5008 11.8807 2.5915 11.0102 1.94522C10.1396 1.29894 9.08422 0.95 8 0.95C6.91578 0.95 5.86035 1.29894 4.9898 1.94522C4.11926 2.5915 3.47982 3.5008 3.16607 4.53862C2.85232 5.57645 2.88091 6.6877 3.24762 7.70802C3.60165 8.69308 4.25268 9.54289 5.10951 10.141C3.94036 10.4435 2.90066 11.1196 2.14953 12.0678C1.37513 13.0454 0.952578 14.2553 0.95 15.5025V15.5026C0.95 15.6485 1.00795 15.7883 1.11109 15.8915C1.21424 15.9946 1.35413 16.0526 1.5 16.0526H14.5C14.6459 16.0526 14.7858 15.9946 14.8889 15.8915C14.9921 15.7883 15.05 15.6485 15.05 15.5026V15.5025C15.0474 14.2553 14.6249 13.0454 13.8505 12.0678C13.0993 11.1196 12.0596 10.4435 10.8905 10.141ZM12.4843 12.1765C13.292 12.8945 13.8104 13.8814 13.9437 14.9526H2.05625C2.18963 13.8814 2.708 12.8945 3.51573 12.1765C4.3374 11.4461 5.40035 11.0458 6.49969 11.0526H6.5L9.5 11.0526L9.50031 11.0526C10.5997 11.0458 11.6626 11.4461 12.4843 12.1765ZM4.05 6.00258C4.05 5.22135 4.28166 4.45766 4.71569 3.80808C5.14973 3.15851 5.76663 2.65223 6.4884 2.35326C7.21017 2.05429 8.00438 1.97607 8.77061 2.12848C9.53683 2.28089 10.2407 2.65709 10.7931 3.20951C11.3455 3.76193 11.7217 4.46575 11.8741 5.23198C12.0265 5.9982 11.9483 6.79242 11.6493 7.51418C11.3504 8.23595 10.8441 8.85286 10.1945 9.28689C9.54493 9.72092 8.78124 9.95258 8 9.95258C6.95239 9.95258 5.9477 9.53642 5.20693 8.79566C4.46616 8.05489 4.05 7.05019 4.05 6.00258Z"
      fill={color}
      stroke="black"
      strokeWidth="0.1"
    />
  </svg>
)

export default UserIcon
