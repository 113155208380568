import { FC } from 'react'
import { templateColor } from 'src/styles'

interface ImagePlaceholderProps {
  width?: number
  height?: number
  mb?: number
  color?: string
}

const ImagePlaceholder: FC<ImagePlaceholderProps> = ({ width, height, mb, color }) => {
  const blockStyle = {
    backgroundColor: color || templateColor,
    borderRadius: 3,
    width: width || 32.5,
    height: height || 50,
    marginBottom: mb || 0,
  }

  return <div style={blockStyle}></div>
}

export default ImagePlaceholder
