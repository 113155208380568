import { FC } from 'react'
import { Pie } from 'react-chartjs-2'
import { OrderResource } from 'src/resources'
import {
  orderCancelledColor,
  orderCompletedColor,
  orderConfirmedColor,
  orderPendingColor
} from 'src/styles'
import './PieChart.scss'

interface PieChartProps {
  orders: OrderResource[] | undefined
}

const PieChart: FC<PieChartProps> = ({ orders }) => {
  const labels = orders?.map((order) => order.status)

  const getOrdersCount = (orderStatus: string): number => {
    const totalCount = orders?.filter(({ status }) => status === orderStatus.toUpperCase()).length
    return totalCount || 0
  }
  const ordersCount = orders?.length ?? 0
  const completedCount = getOrdersCount('COMPLETED')
  const pendingCount = getOrdersCount('PENDING')
  const confirmedCount = getOrdersCount('CONFIRMED')
  const cancelledCount = getOrdersCount('CANCELLED')
  const LEGENDAS = ['Pending', 'Confirmed', 'Completed', 'Cancelled']

  return (
    <div className="PieWrapper">
      <div className="pieContainer">
        <Pie
          data={{
            labels: labels,
            
            datasets: [
              {
                label: 'Order Status',
                data: [completedCount, confirmedCount, pendingCount, cancelledCount],
                backgroundColor: [
                  orderPendingColor,
                  orderConfirmedColor,
                  orderCompletedColor,
                  orderCancelledColor,
                ],
                borderColor: [],
                borderWidth: 0,
              },
            ],
          }}
          options={{
            responsive: true,
            legend: {
              display: false,
            },
          }}
        />
        <div className="totalOrder">
          Total orders<div className="totalOrderNumber">{ordersCount}</div>
        </div>
      </div>
      <div className="orderCaptions">
        {LEGENDAS.map((legenda) => {
          const className = legenda.toLowerCase()
          const value = getOrdersCount(legenda)
          return (
            <div className="orderCaption" key={legenda}>
              <span className={className}></span>
              {legenda} ({value})
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PieChart
