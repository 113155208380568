import { CSSProperties, FC, useState } from 'react'
import { Button } from 'src/components'
import { getCountryName } from 'src/helpers'
import { sleep } from 'src/helpers/sleep'
import { MerchantResource } from 'src/resources'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import '../AccountCard.scss'
import { AccountCardWrapper } from '../components'
import { ApiKeyModal } from './components'
import './MerchantCard.scss'

interface MerchantCardProps {
  role: string
  isAdmin: boolean
  apiKey?: string | undefined
  merchant: MerchantResource
  generateApiKey:
    | ((id: number, role?: string | undefined) => Promise<{ api_key: string }>)
    | undefined
  toggleModal: () => void
  hideApiKey: () => void
}

const MerchantCard: FC<MerchantCardProps> = ({
  merchant,
  role,
  isAdmin,
  apiKey,
  hideApiKey,
  generateApiKey,
  toggleModal,
}) => {
  const {
    name,
    email,
    addressLine1,
    addressLine2,
    hasApiKey,
    zipCode,
    city,
    state,
    countryCode,
    id,
    cancellationRedirectUrl,
    errorRedirectUrl,
    successRedirectUrl
  } = merchant

  const [generatingApiKey, setGeneratingApiKey] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  const hiddenText = '*********'

  const content = [
    {
      row: [
        { label: 'Company name', value: name },
        { label: 'Billing e-mail', value: email },
      ],
    },
    {
      row: [
        { label: 'Billing address line 1', value: addressLine1 },
        { label: 'Billing address line 2', value: addressLine2 || 'Billing address line 2' },
      ],
    },
    {
      row: [
        { label: 'Zip code', value: zipCode },
        { label: 'City', value: city },
      ],
    },
    {
      row: [
        { label: 'State', value: state },
        { label: 'Country code', value: getCountryName(countryCode) },
      ],
    },
    {
      row: [
        { label: 'Peecho api-key', value: hiddenText },
        { label: 'Peecho button key', value: hiddenText },
      ],
    },
    {
      row: [{ label: 'Merchant id', value: id }],
    },
    {
      row: [
        { label: 'Success redirect URL', value: successRedirectUrl ?? 'https://example.com/success' },
        { label: 'Cancellation redirect URL', value: cancellationRedirectUrl ?? 'https://example.com/cancelled' },
        { label: 'Error redirect URL', value: errorRedirectUrl ?? 'https://example.com/error' },
      ],
    },
  ]

  const merchantIdInstructionStyle = {
    fontWeight: 'normal',
    display: 'block',
    minWidth: 'max-content',
    marginTop: 5,
  } as CSSProperties

  const generateKey = async () => {
    if (!generateApiKey) return

    setErrorMessage(undefined)
    setGeneratingApiKey(true)
    try {
      // This goes way to fast
      await sleep(300)
      await generateApiKey(id, role)
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setGeneratingApiKey(false)
    }
  }

  const handleCloseApiModal = () => {
    hideApiKey()
  }

  const text = hasApiKey ? ` ${name} already has an active api key.` : 'Generate your api key.'

  return (
    <AccountCardWrapper section="merchant" toggleModal={toggleModal} isAdmin>
      {content.map(({ row }, i) => (
        <div className="accountCardRow" key={i}>
          {row.map(({ label, value }, j) => {
            const isMerchantId = value === id
            return (
              <div className="accountUserInfo" key={j}>
                <>
                  <div className="accountLabel">
                    {label}
                    {isMerchantId && (
                      <span
                        style={merchantIdInstructionStyle}>
                        {' '}
                        You will need the merchant id to create orders.
                      </span>
                    )}
                  </div>
                  <div className="accountValue">{value}</div>
                </>
              </div>
            )
          })}
        </div>
      ))}
      {isAdmin && (
        <div className="apiKeyWrapper">
          <div style={{ fontWeight: 'bold' }}>{text}</div>
          {hasApiKey &&
            'If needed, you can generate a new one, which will deactivate the current one.'}
          <br />
          The api key is unique to {name} and will be needed to create orders.
          <br /> Please, store it in a safe place
          <Button text="Generate api key" onClick={generateKey} isLoading={generatingApiKey} />
          {errorMessage ? <p className="errorText">{errorMessage}</p> : null}
        </div>
      )}
      {apiKey && <ApiKeyModal onClose={handleCloseApiModal} apiKey={apiKey} />}
    </AccountCardWrapper>
  )
}

export default MerchantCard
