import { FC } from 'react'
import { IconsProps } from './Icon'

const PlusIcon: FC<IconsProps> = ({ color, deg, strokeWidth }) => {
  const transform = `rotate(${deg})`
  return (
    <svg viewBox="0 0 7 7" width="7" height="7" fill="none" transform={transform}>
      <path
        d="M5.5 2.95269H4.05V1.50269C4.05 1.35682 3.99205 1.21692 3.88891 1.11378C3.78576 1.01063 3.64587 0.952686 3.5 0.952686C3.35413 0.952686 3.21424 1.01063 3.11109 1.11378C3.00795 1.21692 2.95 1.35682 2.95 1.50269V2.95269H1.5C1.35413 2.95269 1.21424 3.01063 1.11109 3.11378C1.00795 3.21692 0.95 3.35682 0.95 3.50269C0.95 3.64855 1.00795 3.78845 1.11109 3.89159C1.21424 3.99474 1.35413 4.05269 1.5 4.05269H2.95V5.50269C2.95 5.64855 3.00795 5.78845 3.11109 5.89159C3.21424 5.99474 3.35413 6.05269 3.5 6.05269C3.64587 6.05269 3.78576 5.99474 3.88891 5.89159C3.99205 5.78845 4.05 5.64855 4.05 5.50269V4.05269H5.5C5.64587 4.05269 5.78576 3.99474 5.88891 3.89159C5.99205 3.78845 6.05 3.64855 6.05 3.50269C6.05 3.35682 5.99205 3.21692 5.88891 3.11378C5.78576 3.01063 5.64587 2.95269 5.5 2.95269Z"
        fill={color}
        stroke="black"
        strokeWidth={strokeWidth || '0.1'}
      />
    </svg>
  )
}

export default PlusIcon
