import { FC } from 'react'
import { borderColor } from 'src/styles'
import ImagePlaceholder from './ImagePlaceholder'
import TextLinePlaceholder from './TextLinePlaceholder'
import './TextPagePlaceholder.scss'

interface TextPagePlaceholderProps {
  type: string | 'paragraph' | 'title' | 'columnsAndTitle'
  color?: string
}

const TextPagePlaceholder: FC<TextPagePlaceholderProps> = ({ type, color }) => {
  let placeholder = <div></div>
  const height = 45

  switch (type) {
    case 'paragraph':
      placeholder = (
        <>
          <TextLinePlaceholder color={color} isSmall />
          <TextLinePlaceholder color={color} />
          <TextLinePlaceholder color={color} />
          <TextLinePlaceholder color={color} isSmall />
          <TextLinePlaceholder color={color} />
        </>
      )

      break
    case 'title':
      placeholder = (
        <>
          <TextLinePlaceholder color={color} />
        </>
      )
      break
    case 'columnsAndTitle':
      placeholder = (
        <>
          <div className="columnsForText">
            <ImagePlaceholder color={color} height={height} />
            <ImagePlaceholder color={color} height={height} />
          </div>
          <div>
            <TextLinePlaceholder color={color} />
          </div>
        </>
      )
      break
  }

  return (
    <div className="textPages" style={{ borderColor: `${color || borderColor}` }}>
      <div className="paragraphFrame">{placeholder}</div>
    </div>
  )
}

export default TextPagePlaceholder
