export interface IFontResource {
    id: number
    url: string
    family: string
    created_at: Date
    updated_at: Date
}

export class FontResource {
    public readonly id: number

    public url: string

    public family: string

    public createdAt?: Date

    public updatedAt?: Date

    constructor(fonts: IFontResource) {
        this.id = fonts.id
        this.url = fonts.url
        this.family = fonts.family
        this.createdAt = fonts.created_at ? new Date(fonts.created_at) : new Date()
        this.updatedAt = fonts.updated_at ? new Date(fonts.updated_at) : new Date()
    }
}
