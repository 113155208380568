import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Wrapper } from 'src/components'
import { useAuthState } from 'src/contexts'
import { LoginForm } from './components'
import './Login.scss'

const LoginPage: FC = () => {
  const { login, brand } = useAuthState()
  const title = `Welcome to ${brand}`
  const entry = 'Log in with your email and password or create an account'
  return (
    <Wrapper title={title} entry={entry} >
      {login !== undefined && <LoginForm login={login} />}
      <div className="loginLinks">
        <NavLink to="/forgot-password" className="loginLink">
          I forgot my password
        </NavLink>
        <NavLink to="/sign-up" className="loginLink newAccountLink">
          I want to create a new account
        </NavLink>
      </div>
    </Wrapper>
  )
}

export default LoginPage
