import { FC } from 'react'
import { IconsProps } from './Icon'

const PhotoIcon: FC<IconsProps> = () => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0608 0.666626H1.60622C0.96986 0.666626 0.333496 1.30299 0.333496 1.93935V10.8484C0.333496 11.5484 0.906223 12.1212 1.60622 12.1212H13.0608C13.6971 12.1212 14.3335 11.4848 14.3335 10.8484V1.93935C14.3335 1.30299 13.6971 0.666626 13.0608 0.666626ZM13.0608 10.7975C13.0519 10.8108 13.0368 10.8242 13.0242 10.8353L13.0242 10.8353C13.0187 10.8402 13.0137 10.8446 13.0099 10.8484H1.60622V1.99026L1.65713 1.93935H13.0035C13.0168 1.94823 13.0301 1.96329 13.0413 1.97591C13.0461 1.98139 13.0506 1.98641 13.0544 1.99026V10.7975H13.0608ZM5.10622 6.71208L6.69713 8.62753L8.92441 5.75753L11.788 9.57572H2.87895L5.10622 6.71208Z"
      fill="#F0F2F5"
    />
  </svg>
)

export default PhotoIcon
