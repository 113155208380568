import { IFontResource } from '../fonts/FontResource'
import { ILayoutResource, LayoutResource } from '../layouts/LayoutResource'

export type logoPosition =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left'
  | 'center'
  
export type FilesOrder = 'default' | 'ordered' | 'date'
export interface ICoverLayoutResource {
  id: number
  created_at: Date
  gap: number
  grid_template_areas: string
  is_cover: boolean
  is_protected: boolean
  is_enabled: boolean
  max_landscape: number
  max_portrait: number
  title: string
  updated_at?: Date
}

export interface ITemplateResource {
  id: number
  cover_color: string
  cover_text_color: string
  chapter_cover_color: string
  chapter_cover_text_color: string
  cover_layout: ICoverLayoutResource
  chapter_cover_layout: ICoverLayoutResource
  font: IFontResource
  page_color: string
  page_text_color: string
  logo_position: logoPosition
  logo_size: string
  has_cover_title: boolean
  has_cover_logo: boolean
  has_spine_title: boolean
  has_spine_logo: boolean
  created_at?: Date
  updated_at?: Date
  layouts: ILayoutResource[]
  files_order: FilesOrder
}

export class TemplateResource {
  public readonly id: number

  public cover_color: string

  public cover_layout: ICoverLayoutResource

  public chapter_cover_layout: ICoverLayoutResource

  public font: IFontResource

  public cover_text_color: string

  public chapter_cover_color: string

  public chapter_cover_text_color: string

  public page_color: string

  public page_text_color: string

  public logo_position: logoPosition

  public logo_size: string

  public has_cover_title: boolean

  public has_cover_logo: boolean

  public has_spine_title: boolean

  public has_spine_logo: boolean

  public createdAt?: Date

  public updatedAt?: Date

  public layouts: LayoutResource[]

  public files_order: FilesOrder

  constructor(template: ITemplateResource) {
    this.id = template.id
    this.cover_color = template.cover_color
    this.cover_text_color = template.cover_text_color
    this.cover_layout = template.cover_layout
    this.chapter_cover_layout = template.chapter_cover_layout
    this.chapter_cover_color = template.chapter_cover_color
    this.chapter_cover_text_color = template.chapter_cover_text_color
    this.font = template.font
    this.page_color = template.page_color
    this.page_text_color = template.page_text_color
    this.logo_position = template.logo_position
    this.logo_size = template.logo_size
    this.has_cover_logo = template.has_cover_logo
    this.has_spine_logo = template.has_spine_logo
    this.has_spine_title = template.has_spine_title
    this.has_cover_title = template.has_cover_title
    this.createdAt = template.created_at ? new Date(template.created_at) : new Date()
    this.updatedAt = template.updated_at ? new Date(template.updated_at) : new Date()
    this.layouts = template.layouts ? template.layouts.map((i) => new LayoutResource(i)) : []
    this.files_order = template.files_order
  }
}
