import { FC, useMemo, useRef } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import {
  Account,
  BookPreview,
  Branding,
  ForgotPassword,
  LoadingPage,
  Login,
  Orders,
  Signup,
  Templates,
  WelcomePage
} from 'src/containers'
import { useAuthState, useFontsState, useMerchantState } from 'src/contexts'

const Routes: FC = () => {
  const { getMe, isAuthenticated, isInitialLoading: isAuthLoading } = useAuthState()
  const { getMerchant } = useMerchantState()
  const { getFonts } = useFontsState()

  const { current: me } = useRef(getMe)
  const { current: merchant } = useRef(getMerchant)
  const { current: fonts } = useRef(getFonts)

  const isLoading = isAuthLoading

  useMemo(() => {
    if (!me || !merchant || !fonts) return
    ;(async () => {
      try {
        await me()
        await merchant()
        await fonts()
      } catch (e) {
        console.error(e)
      }
    })()
  }, [me, merchant, fonts])

  return (
    <Router>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Switch>
          <Route exact path="/">
            {isAuthenticated ? <Orders /> : <Redirect to="/login" />}
          </Route>
          <Route path="/orders">{isAuthenticated ? <Orders /> : <Redirect to="/login" />}</Route>
          <Route path="/account">{isAuthenticated ? <Account /> : <Redirect to="/login" />}</Route>
          <Route path="/branding">
            {isAuthenticated ? <Branding /> : <Redirect to="/login" />}
          </Route>
          <Route path="/templates">
            {isAuthenticated ? <Templates /> : <Redirect to="/login" />}
          </Route>
          <Route path="/welcome">
            {isAuthenticated ? <WelcomePage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/preview">
            {isAuthenticated ? <BookPreview /> : <Redirect to="/login" />}
          </Route>
          <Route path="/login" component={Login} />
          <Route path="/sign-up" component={Signup} />
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch>
      )}
    </Router>
  )
}

export default Routes
