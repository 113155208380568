import { FC, ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthState, useMerchantState } from 'src/contexts'
import { sleep } from 'src/helpers/sleep'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { Button } from '../Button'
import './Banner.scss'

const Banner: FC = () => {
  const { generateApiKey, merchant } = useMerchantState()
  const { user } = useAuthState()
  const history = useHistory()

  const [generatingApiKey, setGeneratingApiKey] = useState(false)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()

  if (!merchant || !user) return <></>

  const { isAdmin } = user
  const { hasApiKey } = merchant

  const renderText = (): string | ReactNode => {
    if (!merchant.isVerified) {
      return `Please ${isAdmin ? '' : 'ask an admin to'}confirm the billing email address`
    } else if (!user.isVerified) {
      return 'Please confirm your email address'
    } else {
      return (
        <>
          Don&apos;t forget to generate the API key{' '}
          <span>Remember to store it in a safe place, as you will need it to generate orders</span>
        </>
      )
    }
  }
  const generateKey = async () => {
    if (!generateApiKey || !merchant || !user) return
    history.push('./account')
    setGeneratingApiKey(true)
    try {
      // This goes way to fast
      await sleep(300)
      await generateApiKey(merchant.id, user.role)
    } catch (e) {
      if (isErrorResource(e)) {
        setErrorMessage(e.data.error.message)
      }
    } finally {
      setGeneratingApiKey(false)
    }
  }

  const isVerified = merchant.isVerified && user.isVerified
  const showKeyButton = isVerified && !hasApiKey
  const showBanner = !isVerified || !hasApiKey

  return (
    <>
      {showBanner ? (
        <div className="bannerWrapper">
          <div className="banner">
            <div className="bannerText">{renderText()}</div>
            {showKeyButton && (
              <Button text="Generate api key" onClick={generateKey} isLoading={generatingApiKey} />
            )}
            {errorMessage ? <div className="errorText bannerError">{errorMessage}</div> : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Banner
