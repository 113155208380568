import { FC } from 'react'
import { backgroundColor, subtitleColor, warningColor } from 'src/styles'

interface BrandingWrapperProps {
  title: string
  error?: string | undefined
  instructions?: string | undefined
  isBig?: boolean | undefined
  className?: string
}

const BrandingWrapper: FC<BrandingWrapperProps> = ({
  children,
  instructions,
  title,
  error,
  isBig,
  className,
}) => {
  const containerStyle = {
    backgroundColor: backgroundColor,
    borderRadius: 15,
    padding: '15px 20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  }

  const titleStyle = {
    fontSize: 15,
    fontWeight: 600,
    paddingBottom: isBig ? 20 : 10,
  }

  const instructionStyle = {
    fontSize: 11,
    color: subtitleColor,
    paddingTop: 20,
    textAlign: 'center' as const,
    marginBottom: isBig ? 70 : 0,
  }

  const errorStyle = {
    fontSize: 11,
    color: warningColor,
    textAlign: 'center' as const,
  }

  return (
    <div style={containerStyle} className={className}>
      <div style={titleStyle}>{title}</div>
      {children}
      {error && <div style={errorStyle}>{error}</div>}
      {instructions && <div style={instructionStyle}>{instructions}</div>}
    </div>
  )
}

export default BrandingWrapper
