import { CSSProperties, FC, useMemo } from 'react'
import { Icon } from 'src/components'
import { logoPosition } from 'src/resources'
import { FrontCoverLayout } from './components'
import './CoverPreview.scss'

interface CoverPreviewProps {
  bookBorder: string
  coverColor: string
  coverLayoutId: number
  coverTextColor: string
  fontFamily: string
  hasCoverLogo: boolean
  hasCoverTitle: boolean
  hasSpineLogo?: boolean
  hasSpineTitle: boolean
  logoUrl?: string | undefined
  logoPosition: logoPosition
  logoSize: string
}

const CoverPreview: FC<CoverPreviewProps> = ({
  bookBorder,
  coverColor,
  coverLayoutId,
  coverTextColor,
  fontFamily,
  hasCoverLogo,
  hasCoverTitle,
  // hasSpineLogo,
  hasSpineTitle,
  logoPosition,
  logoSize,
  logoUrl,
}) => {
  const coverStyle = {
    backgroundColor: coverColor,
    border: bookBorder,
    color: coverTextColor,
  }

  const spineStyle = {
    borderTop: bookBorder,
    backgroundColor: coverColor,
    borderBottom: bookBorder,
  }

  
  const getLogoPosition = useMemo((): CSSProperties => {
    let alignItems = 'center'
    let justifyContent = 'center'
    if (logoPosition) {
      if (logoPosition.includes('top')) {
        alignItems = 'flex-start'
      } else if (logoPosition.includes('bottom')) {
        alignItems = 'flex-end'
      }

      if (logoPosition.includes('left')) {
        justifyContent = 'flex-start'
      } else if (logoPosition.includes('right')) {
        justifyContent = 'flex-end'
      }
    }
    return {
      alignItems,
      justifyContent,
    }
  }, [logoPosition])

  const logoSizeScaled = parseInt(logoSize, 10) / 10

  return (
    <div className="coverContainer">
      <div className="CoverWrapper" style={{ color: coverTextColor }}>
        <div className="cover" style={coverStyle}>
          {hasCoverLogo ? (
            <div style={getLogoPosition} className="coverImage">
              {logoUrl ? (<img src={logoUrl} style={{ height: logoSizeScaled || 10 }} />) : (
                <>Please, upload your logo</>
              )}
            </div>
          ) : (
            <>Back cover</>
          )}
        </div>
        <div className="spine" style={spineStyle}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Icon type="spine" />
            {/* Uncomment once SPINE_LOGO is implemented in BE */}
            {/* {has_spine_logo && <img src={logoUrl} style={{ maxWidth: 22, marginTop: 15 }} />} */}
          </div>
          {hasSpineTitle && (
            <div className="spineTitle" style={{ fontFamily }}>
              Title
            </div>
          )}
          <Icon type="spine" direction="up" />
        </div>
        <FrontCoverLayout
          coverLayoutId={coverLayoutId}
          coverStyle={coverStyle}
          hasTitle={hasCoverTitle}
          fontFamily={fontFamily}
        />
      </div>
    </div>
  )
}

export default CoverPreview
