import { FC, useState } from 'react'
import { SuccessAlert } from 'src/components'
import { UserResource } from 'src/resources'
import '../AccountCard.scss'
import { AccountCardWrapper } from '../components'
import { PasswordModalForm } from './components'

interface UserCardProps {
  user: UserResource
  toggleModal: () => void
}

const UserCard: FC<UserCardProps> = ({ user, toggleModal }) => {
  const { firstName, lastName, email, role } = user
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showPasswordSucces, setShowPasswordSuccess] = useState(false)

  const togglePasswordModal = () => setShowPasswordModal(!showPasswordModal)

  const content = [
    {
      row: [
        { label: 'First name', value: firstName },
        { label: 'Last name', value: lastName },
      ],
    },
    {
      row: [
        { label: 'Email Address', value: email },
        { label: 'Role', value: role },
      ],
    },
  ]

  const successMessage = 'Your password has been successfully updated'
  const changePasswordText = 'Click here to change your password'
  return (
    <AccountCardWrapper section="user" toggleModal={toggleModal} isAdmin>
      {content.map(({ row }, i) => (
        <div className="accountCardRow" key={i}>
          {row.map(({ label, value }, j) => (
            <div className="accountUserInfo" key={j}>
              <div className="accountLabel">{label}</div>
              <div className="accountValue">{value}</div>
            </div>
          ))}
        </div>
      ))}
      <button onClick={togglePasswordModal}>
        {changePasswordText}
      </button>
      {showPasswordModal && (
        <PasswordModalForm
          toggleModal={togglePasswordModal}
        />
      )}
      {showPasswordSucces && (
        <SuccessAlert onClose={() => setShowPasswordSuccess(false)} message={successMessage} />
      )}
    </AccountCardWrapper>
  )
}

export default UserCard
