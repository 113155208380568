import { FC } from 'react'
import { LoadingAnimation, Wrapper } from 'src/components'
import { useMerchantState } from 'src/contexts'
import { OrdersPlaceholder, PieChart } from './components'
import './Orders.scss'

const Orders: FC = () => {
  const { isLoading, merchant } = useMerchantState()

  const ordersCount = merchant?.orders?.length ?? 0

  if (isLoading) {
    return (
      <Wrapper>
        <div style={{ display: 'flex', justifyContent: 'center', padding: 100 }}>
          <LoadingAnimation />
        </div>
      </Wrapper>
    )
  }

  if (ordersCount === 0) {
    return (
      <Wrapper>
        <OrdersPlaceholder />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {merchant && merchant.orders && (
        <div className="ordersWrapper">
          <PieChart orders={merchant.orders} />
          <div className="ordersDetails">
            <div>
              <div className="dataTitles">
                <div className="orderId">Order number</div>
                <div className="orderDate">Date/Time</div>
                <div className="orderStatus">Status</div>
              </div>

              {merchant.orders.map((order) => {
                const { id, status, updatedAt } = order
                const lowerStatus = status.toLowerCase()
                return (
                  <div key={id} className="dataRow">
                    <div className="data1">{id}</div>
                    <div className="data2">{updatedAt}</div>
                    <div className={`data3 ${lowerStatus}`}>
                      <div>{lowerStatus}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default Orders
