import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, DropZone } from 'src/components'
import { sleep } from 'src/helpers/sleep'
import { MerchantResource } from 'src/resources'
import { isErrorResource } from 'src/resources/errors/ErrorResource'
import { BrandingWrapper } from '../BrandingWrapper'
import './LogoCard.scss'

interface LogoCardProps {
  isCover?: boolean
  logoUrl?: string | undefined
  merchantId: number
  error?: string | undefined
  uploadLogo: ((id: number, info, setAsCover) => Promise<MerchantResource | undefined>) | undefined
}

const LogoCard: FC<LogoCardProps> = ({ isCover, logoUrl, uploadLogo, merchantId, error }) => {
  const [newLogo, setLogo] = useState<File | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cardError, setError] = useState<string | undefined>('')

  const initialValues = {
    logo: logoUrl,
  }

  const onSubmit = async () => {
    if (!uploadLogo) return
    setError(undefined)
    setIsSubmitting(true)

    try {
      if (merchantId) {
        await sleep(500)
        await uploadLogo(merchantId, { logo: newLogo }, isCover)
      }
    } catch (e) {
      if (isErrorResource(e)) {
        setError(e.data.error.message)
      }
    } finally {
      setIsSubmitting(false)
      setLogo(undefined)
    }
  }

  const buttonText = `${logoUrl !== null ? 'Change' : 'Apply'} logo`
  const title = `${isCover ? 'Cover' : 'Spine'} Logo`
  const dropZoneMessage = `Upload ${title.toLowerCase()}`
  const instructions = 'Click the box or drag and drop to upload logo'
  return (
    <BrandingWrapper title={title} error={cardError} instructions={instructions} className="logo-wrapper">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue, isValid }) => {
          const disabled = newLogo === undefined || !isValid
          return (
            <Form onSubmit={handleSubmit} className="LogoWrapper">
              <DropZone
                message={dropZoneMessage}
                logo={logoUrl || newLogo}
                previewImage={newLogo}
                setFile={(file) => {
                  setFieldValue('logo', { ...file })
                  setLogo(file)
                }}
                error={error}
              />
              <Button
                theme="light"
                type="submit"
                isLoading={isSubmitting}
                disabled={disabled}
                text={buttonText}
                mb={1}
                mt={1}
                bold
              />
            </Form>
          )
        }}
      </Formik>
    </BrandingWrapper>
  )
}

export default LogoCard
