import './App.scss'
import { AuthProvider, FontsProvider, MerchantProvider, TemplateProvider } from './contexts'
import { Routes } from './router'

const App = (): JSX.Element => {
  return (
    <AuthProvider>
      <MerchantProvider>
        <TemplateProvider>
          <FontsProvider>
            <Routes />
          </FontsProvider>
        </TemplateProvider>
      </MerchantProvider>
    </AuthProvider>
  )
}

export default App
